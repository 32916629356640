
import { SET_FLASH_MSG, SET_LOADER } from './../actions/others.action';
import shortid from "shortid"
import { isEmpty } from "lodash"
export const others = (state = { message: {}, isShow: false }, action = {}) => {
    switch (action.type) {
        case SET_FLASH_MSG:
         //   console.log(action)

            return {
                ...state,
                message: {
                    id: shortid.generate(),
                    isShow: !isEmpty(action.payload),
                    type: action.payload.type,
                    msg: action.payload.msg
                }

            }

        case SET_LOADER:
         
            return {
                ...state, ...action.payload
            }

        default: return state
    }

}