import { fork } from 'redux-saga/effects';
import Clients from './clients.saga';
import Languages from './languages.saga';
import Projects from './projects.saga';
import Videos from './videos.saga';
import Galleries from './gallery.saga';
import Users from './users.saga';


export default function* rootSaga() {
  yield fork(Clients);
  yield fork(Languages);
  yield fork(Projects);
  yield fork(Videos);
  yield fork(Galleries);
  yield fork(Users);
}
