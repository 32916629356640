/* import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import reducer from '../reducers';
import rootSaga from '../sagas';

export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        reducer,
        initialState,
        applyMiddleware(
            sagaMiddleware, logger
        )
    );
    sagaMiddleware.run(rootSaga);
    return store;
};

 */

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
//import logger from 'redux-logger';
import reducer from '../reducers';
import rootSaga from '../sagas';
import CheckTokenExpired from '../utils/checkExpiredToken';
import setAuthorizationToken from '../utils/setAuthToken';
import { requestSetCurrentUser } from '../actions/others.action';
//import createHistory from 'history';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
//import { routerMiddleware } from 'react-router-redux';


const persistConfig = {
    key: 'root',
    storage,
  }
  //const routerMw = routerMiddleware(createHistory());
  const persistedReducer = persistReducer(persistConfig, reducer)
export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();

   // const history = createHistory();
    const ExpiredSession = store => next => action => {
        //   console.log("Middleware triggered:", action);
        if (CheckTokenExpired(sessionStorage.jwtToken)) {
            sessionStorage.removeItem('jwtToken')
            setAuthorizationToken(false)
            store.dispatch(requestSetCurrentUser({}))
        }
        next(action);
    }
    const store = createStore(
        persistedReducer,
        initialState,
        applyMiddleware(
            sagaMiddleware, ExpiredSession, //routerMiddleware(history)
        )
    );
    let persistor = persistStore(store)
    sagaMiddleware.run(rootSaga);
    return { store, persistor }
};

