import { SUCCESS_GET_PROJECTS, SUCCESS_GET_PROJECT, SUCCESS_CREATEPROJECT, SUCCESS_UPDATEPROJECT, SUCCESS_DELETEPROJECT, SET_PROJECTID } from "../actions/projects.action"

const initialState = {
    projects: [],
    projectID: ""
}

let project = [];
export const projects = (state = initialState, { type, payload }) => {
    switch (type) {
        case SUCCESS_GET_PROJECTS:
            return {
                ...state, ...payload
            }
        case SUCCESS_GET_PROJECT:
            return {
                ...state, ...payload
            }

        case SET_PROJECTID:
            return {
                ...state, ...payload
            }
        case SUCCESS_CREATEPROJECT:
            return {
                projects: [...state.projects, { ...payload }]
            }
        case SUCCESS_UPDATEPROJECT:
            const index = state.projects.indexOf(state.projects.find(item => payload.id === item.id));
            project = [...state.projects];
            if (index > -1) {
                project[index].name = payload.name;
                project[index].code = payload.code;
                project[index].description = payload.description;

            }
            return {
                ...state,
                projects: project
            };

        case SUCCESS_DELETEPROJECT:
            project = state.projects.filter(item => {
                return payload.id !== item.id;
            });
            return {
                ...state,
                projects: project
            };
        default: return state;
    }
} 
