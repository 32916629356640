import axios from "axios";
//const baseURL = 'http://localhost:4000/v1/projects'
//const baseURL = 'https://api.citybibles.ga/v1/projects'
//const baseURL = 'https://citybible.herokuapp.com/v1/projects'

import baseURL from "./"
//Client API

export const AddGallery = async (token, name, avatar,  project, client) => {
    // console.log(patientId, scanId, File[0])
    //  const form = new FormData();
    // form.set("scanId", scanId)
    //  form.append("File", File)
    var form_data = new FormData();
    form_data.append('avatar', avatar);
    form_data.set("name", name)
    form_data.set("project", project)
    form_data.set("client", client)
    //  console.log(form_data)
    try {
        const result = await  axios.post(`${baseURL}/projects/items`, form_data, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': "Bearer " + token
             //   'Acces-Control-Allow-Origin': 'https://city-bible.surge.sh'
            }
        })

      /*   const result = await axios({
            method: 'post',
            url: `${baseURL}/items`,
            data: form_data,
            withCredentials: true,
            
           headers: {
                //  'Content-Type': 'multipart/form-data',
               'Access-Control-Allow-Origin': true,
               // 'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },  
        }) */
      //  console.log(result)
        return result
    }
    catch (error) {
       // console.log(error.response);
        return await error.response;
    }
/* 
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    credentials: 'same-origin', */
};

/* 
export const AddGallery = async (token, name, avatar, project, client) => {
  console.log(avatar)
  try {
      const result = await axios.post(
          `${baseURL}/items`, {
          name,
          avatar,
          project,
          client
      }, {
          headers: {
              Authorization: "Bearer " + token
          }
      }
      );
      return result
  } catch (error) {
      return await error.response;
  }
};
*/
export const UpdateGallery = async (token, id, name, avatar) => {
    try {
        const result = await axios.patch(
            `${baseURL}/projects/items/${id}`, {
            name, avatar
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const DeleteGallery = async (token, id) => {
    try {
        const result = await axios.delete(
            `${baseURL}/projects/items/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const GetGalleries = async (token, project) => {
    try {
        const result = await axios.get(`${baseURL}/projects/${project}/items`
            , {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        );
        return result;
    } catch (error) {
       // console.log(error.response)
        return await error.response;
    }
};


export const GetGallery = async (token, project, id) => {
    try {
        const result = await axios.get(`${baseURL}/projects/${project}/items/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return result;
    } catch (error) {
        return await error.response;
    }
};

export const GetProjectGalleries = async (token, project) => {
    try {
        const result = await axios.get(`${baseURL}/projects/${project}/items`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return result;
    } catch (error) {
        return await error.response;
    }
};

