import React from 'react';
import { Form, Input, Button, notification, Spin, } from 'antd';
import { requestUpdateProject } from '../../modules/actions/projects.action';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};



const EditProject = (props) => {
    const { data } = props;
    const [form] = Form.useForm();
    React.useEffect(() => {
        form.setFieldsValue({
            name: data.name,
            code: data.code,
            description: data.description,

        });

    }, [data])
    const onFinish = values => {
        const id = data.id;
        const datax = {
            id,
            ...values
        }
      
        props.UpdateProject({ token: sessionStorage.getItem("jwtToken"), values: { ...datax } });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (

        <Form
            /*  {...layout} */
            name="basic"
            initialValues={{
                remember: true,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
        >
            <Form.Item
                //  size="large"
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Project Name',
                    },
                ]}
            >
                <Input placeholder="Project Name" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Code"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Code',
                    },
                ]}
            >
                <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Description',
                    },
                ]}
            >
                <Input placeholder="Description" />
            </Form.Item>



            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
        </Button>
            </Form.Item>
        </Form>

    );
};
EditProject.prototype = {
    data: PropTypes.object.isRequired,
    UpdateProject: PropTypes.func.isRequired,

}

const mapStateToProps = (state, props) => {
    const data = props.data;

    return {
        data,
    }
}
export default connect(mapStateToProps, { UpdateProject: requestUpdateProject })(EditProject);
