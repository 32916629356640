import axios from "axios";
//const baseURL = 'https://api.citybibles.ga/v1/clients'

import baseURL from "./"
//Client API

export const AddProject = async (token, name, code, description, client) => {
    try {
        const result = await axios.post(
            `${baseURL}/clients/projects`, {
            name,
            code,
            description,
            client
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const UpdateProject = async (token, id, name, code, description) => {
    try {
        const result = await axios.patch(
            `${baseURL}/clients/projects/${id}`, {
            name, code, description
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const DeleteProject = async (token, id) => {
    try {
        const result = await axios.delete(
            `${baseURL}/clients/projects/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const GetProjects = async (token, client) => {
    try {
        const result = await axios.get(`${baseURL}/clients/${client}/projects`
            , {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        );
        return result;
    } catch (error) {
        console.log(error.response)
        return await error.response;
    }
};


export const GetProject = async (token, client, id) => {
    try {
        const result = await axios.get(`${baseURL}/clients/${client}/projects/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return result;
    } catch (error) {
        return await error.response;
    }
};
