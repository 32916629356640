import React from "react";
import { Layout } from 'antd';

import Sidebar from "../Sidebar";
import MainHeader from "../Header";
//import MainFooter from "../Footer";
//import PageHeader from "../../common/PageHeader";
import PageLayout from "../Content";


const { Content } = Layout;

class MainLayout extends React.Component {
    state = {
        collapsed: false,
        visible: false,
    }; 

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleMenuClick = e => {
        if (e.key === '3') {
            this.setState({ visible: false });
        }
    };

    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    render() {

        const { visible, collapsed } = this.state;
        const { handleMenuClick, toggle, handleVisibleChange, } = this;

        return (
            <Layout>
                <Sidebar collapsed={collapsed} />

                <Layout className="site-layout app-header">
                    <MainHeader
                        handleMenuClick={handleMenuClick}
                        toggle={toggle}
                        handleVisibleChange={handleVisibleChange}
                        visible={visible}
                    />
                    <Content className="site-layout" style={{ padding: '0 50px' }}>
                    {/*     <PageHeader /> */}
                        <PageLayout
                          //  children={this.props.children}
                        />
                    </Content>
                </Layout>
             {/*    <MainFooter /> */}

            </Layout>
        );
    }
}

export default MainLayout;