import React from 'react';
import { Form, Input, Button, Spin, notification, } from 'antd';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestCreateTranslate } from '../../modules/actions/languages.action';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};


const Translate = (props) => {
    const { data } = props;
    const [form] = Form.useForm();
    React.useEffect(() => {
        if (data) {
            //  console.log(data.about.name)
            form.setFieldsValue({
                about: {
                    answer: data.about ? data.about.answer : null,
                    url: data.about ? data.about.url : null,
                },
                contact: {
                    answer: data.contact ? data.contact.answer : null,
                    url: data.contact ? data.contact.url : null,
                },
                partners: {
                    answer: data.partners ? data.partners.answer : null,
                    url: data.partners ? data.partners.url : null,
                },
                thanks: {
                    answer: data.thanks ? data.thanks.answer : null,
                    url: data.thanks ? data.thanks.url : null,
                },
                webShop: {
                    answer: data.webShop ? data.webShop.answer : null,
                    url: data.webShop ? data.webShop.url : null,
                },
                welcome: {
                    answer: data.welcome ? data.welcome.answer : null,
                    url: data.welcome ? data.welcome.url : null,
                },
                language: {
                    answer: data.language ? data.language.answer : null,
                    url: data.language ? data.language.url : null,
                },
                how_to_use: {
                    answer: data.how_to_use ? data.how_to_use.answer : null,
                    url: data.how_to_use ? data.how_to_use.url : null,
                },
                scan_qr_codes: {
                    answer: data.scan_qr_codes ? data.scan_qr_codes.answer : null,
                    url: data.scan_qr_codes ? data.scan_qr_codes.url : null,
                },
                scan_pictures: {
                    answer: data.scan_pictures ? data.scan_pictures.answer : null,
                    url: data.scan_pictures ? data.scan_pictures.url : null,
                },
                choose_language: {
                    answer: data.choose_language ? data.choose_language.answer : null,
                    url: data.choose_language ? data.choose_language.url : null,
                },
                care: {
                    answer: data.care ? data.care.answer : null,
                    url: data.care ? data.care.url : null,
                },
                bible_app: {
                    answer: data.bible_app ? data.bible_app.answer : null,
                    url: data.bible_app ? data.bible_app.url : null,
                }




            });
        }
    }, [data])
    const onFinish = values => {

        const datax = {
            client: props.clientID,
            lang: props.lang,
            ...values
        }


        props.UpdateTranslate({ token: sessionStorage.getItem("jwtToken"), values: { ...datax } });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    return (

        <Form
            /*  {...layout} */
            name="complex-form"
            // labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
        /* labelCol={{
            span: 6,
        }}
        wrapperCol={{
            span: 16,
        }} */
        >
            <Form.Item label="About">
                <Input.Group compact>
                    <Form.Item
                        name={['about', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['about', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>



            <Form.Item label="How to use">
                <Input.Group compact>
                    <Form.Item
                        name={['how_to_use', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['how_to_use', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item label="Partners">
                <Input.Group compact>
                    <Form.Item
                        name={['partners', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['partners', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>



            <Form.Item label="Contact">
                <Input.Group compact>
                    <Form.Item
                        name={['contact', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['contact', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>



            <Form.Item label="Bible App">
                <Input.Group compact>
                    <Form.Item
                        name={['bible_app', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['bible_app', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item label="Care">
                <Input.Group compact>
                    <Form.Item
                        name={['care', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['care', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item label="Webshop">
                <Input.Group compact>
                    <Form.Item
                        name={['webShop', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['webShop', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>

            <Form.Item label="Scan QR codes">
                <Input.Group compact>
                    <Form.Item
                        name={['scan_qr_codes', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['scan_qr_codes', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item label="Scan Pictures">
                <Input.Group compact>
                    <Form.Item
                        name={['scan_pictures', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['scan_pictures', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>



            <Form.Item label="Choose language">
                <Input.Group compact>
                    <Form.Item
                        name={['choose_language', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['choose_language', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item label="Thank you">
                <Input.Group compact>
                    <Form.Item
                        name={['thanks', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['thanks', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>



            <Form.Item label="Welcome">
                <Input.Group compact>
                    <Form.Item
                        name={['welcome', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['welcome', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>

            <Form.Item label="Language">
                <Input.Group compact>
                    <Form.Item
                        name={['language', 'answer']}
                        noStyle
                    //  rules={[{ required: true, message: 'Province is required' }]}
                    >
                        <Input placeholder="No Translation" style={{ width: '49%', marginRight: '5px' }} />

                    </Form.Item>
                    <Form.Item
                        name={['language', 'url']}
                        noStyle
                    //  rules={[{ required: true, message: 'Street is required' }]}
                    >
                        <Input style={{ width: '50%' }} placeholder="Url" defaultValue="" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Update
        </Button>
            </Form.Item>
        </Form>

    );
};
Translate.prototype = {
    data: PropTypes.object.isRequired,
    UpdateTranslate: PropTypes.func.isRequired,

}
const mapStateToProps = (state, props) => {
    // console.log(state)
    const data = props.data ? props.data[0] : {};
    const lang = props.lang;
    //   console.log(data)
    const clientID = props.clientID;

    return {
        data,
        lang,
        clientID,

    }
}


export default connect(mapStateToProps, { UpdateTranslate: requestCreateTranslate, })(Translate);
