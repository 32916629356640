import React from 'react';
import { Menu } from 'antd';
import {
    UserOutlined,
    VideoCameraOutlined,
    // UploadOutlined,
    // MailOutlined
} from '@ant-design/icons';
import jwtDecode from "jwt-decode"
import {
    Link, withRouter
} from "react-router-dom";
//const { SubMenu } = Menu;
const SideNav = (props) => {
    const [defaultSelect, SetDefaultSelect] = React.useState("0")

    React.useEffect(() => {
        switch (props.history.location.pathname) {
            case "/users":
                SetDefaultSelect("1")
                break;
            case "/projects":
                SetDefaultSelect("1")
                break;

            default:
                SetDefaultSelect("2")
                break;
        }
    }, [parseInt(defaultSelect) > 0])

    // console.log(props.history.location.pathname)
    const decode = sessionStorage.getItem('jwtToken') !== null ? jwtDecode(sessionStorage.getItem('jwtToken')) : { accout_type: "user" }
    // const decode = { accout_type: "user" }
    //  console.log(decode)

    const AdminMenu = () => (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[defaultSelect]}>
            <Menu.Item key="1" onClick={() => SetDefaultSelect("1")}>
                <Link to="/users">
                    <UserOutlined />
                    <span>  &nbsp;User</span>
                </Link>

            </Menu.Item>
            <Menu.Item key="2" onClick={() => SetDefaultSelect("2")}>
                <Link to="/clients">
                    <VideoCameraOutlined />
                    <span>  &nbsp;Client</span>
                </Link>
            </Menu.Item>
        </Menu>
    )
    const UserMenu = () => (
        <Menu theme="light" mode="inline" defaultSelectedKeys={[defaultSelect]}>
            <Menu.Item key="1" onClick={() => SetDefaultSelect("1")}>
                <Link to="/projects">
                    <UserOutlined />
                    <span>  &nbsp;Project</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => SetDefaultSelect("2")}>
                <Link to="/languages">
                    <VideoCameraOutlined />
                    <span>  &nbsp;Language</span>
                </Link>
            </Menu.Item>
        </Menu>
    )


    return (
        <React.Fragment>
            {
                decode.account_type === "admin" ?
                    < AdminMenu />
                    :
                    <UserMenu />
            }

        </React.Fragment>
    );
};

/* const mapStateToProps = (state, props) => {

} */

export default withRouter(SideNav);