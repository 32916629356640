
import {
    call,
    fork,
    //   take,
    put,
    takeEvery
} from 'redux-saga/effects';
import { AddProject, UpdateProject, DeleteProject, GetProject, GetProjects } from '../api/projects.api';
import { REQUEST_CREATEPROJECT, successCreateProject, failureCreateProject, REQUEST_UPDATEPROJECT, successUpdateProject, failureUpdateProject, REQUEST_DELETEPROJECT, successDeleteProject, failureDeleteProject, successGetProject, failureGetProject, REQUEST_GET_PROJECT, REQUEST_GET_PROJECTS, failureGetProjects, successGetProjects } from '../actions/projects.action';
import { requestSetLoader, requestSetFlashMsg } from '../actions/others.action';
import AlertMessage from '../../components/shared/Alert/message';
import openNotificationWithIcon from '../../components/shared/Alert/notification';

const AddNewProject = async (token, data) => {
    const {
        name, code, description, client
    } = data
    const result = await AddProject(token, name, code, description, client);
    return {
        result
    }
}

function* handleSubmitNewProject({ payload: { token, values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)

    const {
        result
    } = yield call(AddNewProject, token, values);

    if (result.status === 201) {
        yield put(successCreateProject(result.data.result.project))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Save Status', "Project Saved Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', result.error)

        yield put(failureCreateProject(result.error))
    }
}


const UpdateExistProject = async (token, data) => {
    const { id, name, code, description } = data;
    const result = await UpdateProject(token, id, name, code, description)
    return result
}

function* handleSubmitUpdateProjects({ payload: { token, values } }) {

    AlertMessage("loading", "Updating...", "loading", 0)

    const result = yield call(UpdateExistProject, token, values);

    if (result.status === 201) {
        yield put(successUpdateProject(result.data.result.project))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "Project Updated Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Data Error', result.error)

        yield put(failureUpdateProject(result.error))
    }
}


/* Delete Existing Projects */

const DeleteExistProject = async (token, data) => {
    const { id } = data;
    const result = await DeleteProject(token, id);
    return result;
}

function* handleSubmitDeleteProjects({ payload: { token, values } }) {
    AlertMessage("loading", "Deleting...", "loading", 0)

    const result = yield call(DeleteExistProject, token, values);
    if (result.status === 201) {
        yield put(successDeleteProject(result.data.project))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Delete Status', "Project Deleted Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Delete Data Error', result.error)

        yield put(failureDeleteProject(result.error))
    }
}

/* Get Projects */

const getOneProjects = async (token, client, id) => {
    const result = await GetProject(token, client, id);
    return result
}

/* Get Projects Handler */
function* runGetProject({ payload: { token, client, id } }) {
    const result = yield call(getOneProjects, token, client, id)
    if (result.status === 200) {
        yield put(successGetProject({

            projects: result.data.results.projects
        }));
    } else {
        yield put(failureGetProject(result.error));
    }
}

const getAllProjects = async (token, client) => {
    // console.log(client)
    const result = await GetProjects(token, client);
    return result

}

function* runGetProjects({ payload: { token, client } }) {
    const result = yield call(getAllProjects, token, client)
    //   console.log(result)
    if (result.status === 200) {
        yield put(successGetProjects({

            projects: result.data.result.projects
        }));
    } else {
        yield put(failureGetProjects(result.error));
    }
}

/* Get Projects Watcher */
function* watchGetProjects() {
    yield takeEvery(REQUEST_GET_PROJECTS, runGetProjects)
    yield takeEvery(REQUEST_GET_PROJECT, runGetProject)
    yield takeEvery(REQUEST_CREATEPROJECT, handleSubmitNewProject)
    yield takeEvery(REQUEST_UPDATEPROJECT, handleSubmitUpdateProjects)
    yield takeEvery(REQUEST_DELETEPROJECT, handleSubmitDeleteProjects)
}

export default function* rootSaga() {

    yield fork(watchGetProjects)
}
