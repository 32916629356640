import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChipTable from './../../components/DataTable/index';
import NewGallery from './NewForm';
import FormModal from '../../components/modals/FormModal';
import PageTitle from '../../components/common/PageTitle';
import { Popconfirm, Button, Input, message, Dropdown, Menu, Badge, Avatar } from 'antd';
import { requestGetGalleries, requestDeleteGallery, requestSetGalleryIDS } from "../../modules/actions/items.action"
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EyeFilled, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
//import EditGallery from './EditForm';
let searchInput;

const Galleries = (props) => {

    const [visible, setVisible] = React.useState(false);
    // const [show, setShow] = React.useState(false);
    //const [selData, SetSelData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);

    const [state, setState] = React.useState({
        searchText: '',
        searchedColumn: '',
    });
    //this.props.location.state.detail
    React.useEffect(() => {

        setLoading(true);
        props.GetGalleries({ token: sessionStorage.getItem("jwtToken"), project: props.projectID })

        delayTime(3000)
    }, [false])

    const delay = time => {
        return new Promise((resolve, reject) => {
            if (isNaN(time)) {
                reject(new Error("Delay Requires a Valid Number"));
            } else {
                setTimeout(resolve, time);
            }
        });
    };

    const delayTime = async time => {
        await delay(time)
            .then(() => {
                setLoading(false);
                setHasData(true);
            })
    };

    const handleOk = (e) => {
        console.log(e);
        setVisible(false);
        //  setShow(false);
    }

    const handleCancel = (e) => {
        console.log(e);
        setVisible(false);
        //   setShow(false);
    }

    function confirm(e, record) {
        //   console.log(e, record);
        props.DeleteGallery({ token: sessionStorage.getItem("jwtToken"), values: record })
       
    }

    function cancel(e) {
        //  console.log(e);
        message.error('Click on No');
    }



    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleNextPage = (record) => {
        //  console.log(record)
        props.selectImage({ imageID: { itemID: record.id, vuforia: record.vuforia } })
        props.history.push({
            pathname: '/videos',
            state: { itemID: record.id, vuforia: record.vuforia, clientID: props.clientID, projectID: props.projectID }
        })
    }


    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '', searchedColumn: '' });
    };

    /*  const EditDataHandler = (record) => {
         SetSelData(record)
         setShow(true);
     } */

    const columns = [
        {
            title: 'Reference',
            dataIndex: 'vuforia',
            key: 'vuforia',
            ...getColumnSearchProps('vuforia'),
        },
        {
            title: 'Image',
            dataIndex: 'name',
            key: 'name',
           
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            ...getColumnSearchProps('createdDate'),
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <span style={{ marginRight: 16 }}>
                        <Dropdown

                            overlay={
                                (
                                    <Menu>
                                        {/*   <Menu.Item key="1">
                                        <Button type="link" onClick={() => EditDataHandler(record)} icon={<EditOutlined />} size="large">
                                            Edit Gallery
                                </Button>


                                    </Menu.Item> */}
                                        <Menu.Item key="2">
                                            <Badge className="site-badge-count-109" style={{ backgroundColor: '#52c41a' }} >
                                                <Button type="dashed" size="large" onClick={() => handleNextPage(record)}> <EyeFilled /> View Videos</Button>
                                            </Badge>
                                        </Menu.Item>
                                    </Menu>)

                            } placement="bottomRight" trigger={['click']}>
                            <Button type="primary"
                                shape="circle"
                                icon={<MoreOutlined style={{ fontWeight: 700 }} />}
                                size="large" />
                        </Dropdown>
                    </span>

                    <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => confirm(e, record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" shape="circle" danger icon={<DeleteOutlined />} size="large" />
                    </Popconfirm>
                </>
            ),
        },
    ];



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    return (
        <div>
            <PageTitle title={"Galleries"}
                button="Add Gallery"
                click={() => setVisible(true)}
            />

            <FormModal
                children={<NewGallery clientID={props.clientID} projectID={props.projectID} />}
                title={"Add New Gallery"}
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            {/*  <FormModal
                children={<EditGallery data={selData} clientID={props.clientID} />}
                title={"Edit  Gallery"}
                visible={show}
                handleOk={handleOk}
                handleCancel={handleCancel}
            /> */}

            <ChipTable
                id={[Math.random()]}
                key={Math.random()}
                data={props.galleries}
                columns={columns}
                rowSelection={rowSelection}
                loading={loading}
                hasData={hasData}
            />
        </div>
    );
};
Galleries.prototype = {
    GetGalleries: PropTypes.func.isRequired,
    DeleteGallery: PropTypes.func.isRequired,
    clientID: PropTypes.string.isRequired,
    projectID: PropTypes.string.isRequired,
    galleries: PropTypes.array.isRequired,
}
const mapStateToProps = (state, props) => {
    // console.log(state)
    const baseUrl = "https://cityapp-production.s3.us-east-2.amazonaws.com/production/upload/city-bibles-scanning-app/item/avatar/"
    const galleries = state.galleries.galleries && state.galleries.galleries.map(({ name, createdAt, id, vuforia }) => {
        const created_date = new Date(createdAt);
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        var year = created_date.getFullYear();
        var month = months[created_date.getMonth()];
        var date = created_date.getDate();

        var cdate =
            date +
            ", " +
            month +
            " " +
            year;
        return {
            id,
            key: id,
            createdDate: cdate,
            vuforia,
            //https://cityapp-production.s3.us-east-2.amazonaws.com/production/upload/city-bibles-scanning-app/item/avatar/48/2-Marker_Johannes_2.jpg
            name: <Avatar src={`${baseUrl}${name}`} size={100} />
        }
    })
    // console.log(state)
    const projectID = state.projects.projectID  //props.location.state.d[etail.id
    const clientID = state.clients.clientID  //props.location.state.clientID
    const user = state.users.user
    return {
        galleries,
        clientID,
        projectID, user
    }
}

export default connect(mapStateToProps, { GetGalleries: requestGetGalleries, DeleteGallery: requestDeleteGallery, selectImage: requestSetGalleryIDS })(withRouter(Galleries));