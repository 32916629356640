import axios from "axios";
//const baseURL = 'http://localhost:4000'
//const baseURL = 'https://aby-delivery.herokuapp.com'
//const baseURL = 'https://api.citybibles.ga/v1'
//const baseURL = 'http://localhost:4000/api/v1'


import baseURL from "./"
//Login Api

export const LoginUser = async (email, password) => {
    //console.log(email,password)

    try {

        const result = await axios({
            method:'POST',
            url: `${baseURL}/users/login`,
            data: {
                email, password
            },
            /*  headers: {
                'Access-Control-Allow-Origin': '*',
            },  */
            withCredentials: true, 
        })
    
     /*    const result = await axios({
            method: 'POST',
            url: `${baseURL}/users/login`,
            headers: {
                //  'Content-Type': 'multipart/form-data',
                //  'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json',
                //  'Authorization': "Bearer " + token
                'Access-Control-Allow-Origin': 'http://citybible.trinitysoftwarecenter.com/',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type',
                'Access-Control-Max-Age': 86400
            },
            data: {
                email, password
            },
            withCredentials: true,
        }) */
      /*   const result = await axios.post(
             `${baseURL}/users/login`,
             {
                 email, password
             },
             {
                 headers: {
     
                  //   withCredentials: true,
     
                 }
             }
         );  */
     //   console.log(result)
        return result
    } catch (error) {
        return await error.response;
    }
};

export const Logout = async () => {
    try {

        const result = await axios({
            method: 'POST',
            url: `${baseURL}/users/logout`,
            withCredentials: true,

        })
        /* 
        const result = await axios.post(
            `${baseURL}/users/logout`, {
            headers: {

                withCredentials: true,

            }
        }); */

        return result
    } catch (error) {
        return await error.response;
    }
};

//Users API
export const AddUser = async (data) => {
    try {
        const result = await axios.post(
            `${baseURL}/users`, {
            ...data
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const UpdateUser = async (id, data) => {
    try {
        const result = await axios.patch(
            `${baseURL}/users/${id}`, {
            ...data
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const UpdatePassword = async (id, oldpassword, password) => {
    try {
        const result = await axios.patch(
            `${baseURL}/users/${id}/changepwd`, {
            oldpassword, password
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const DeleteUser = async (id) => {
    try {
        const result = await axios.delete(
            `${baseURL}/users/${id}`
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const getUsers = async () => {
    try {
        const result = await axios.get(`${baseURL}/users`
            /*   , {
                headers: {
                  authorization: "Bearer " + token
                }
              } */
        );
        return result;
    } catch (error) {
        return await error.response;
    }
};


export const getUser = async (bank, id) => {
    try {
        const result = await axios.get(`${baseURL}/banks/${bank}/branches/${id}`);
        return result;
    } catch (error) {
        return await error.response;
    }
};