import React from 'react';
import { withRouter } from 'react-router-dom';

import UpdateProfile from './EditForm';
import PageTitle from '../../components/common/PageTitle';

const UserProfile = () => {
    return (
        <div>
            <PageTitle title={"Update Profile"} />
            <UpdateProfile  />
        </div>
    );
};



export default withRouter(UserProfile);