import {
    combineReducers
} from "redux"

import { clients } from "./clients.reducer";
import { languages } from "./languages.reducer";
import { projects } from "./projects.reducer";
import { videos } from "./videos.reducer";
import { galleries } from "./gallery.reducer";
import { others } from "./others.reducer";

import storage from 'redux-persist/lib/storage';
import { users } from "./auth.reducer"
import { SUCCESS_LOGOUT } from "../actions/user.action";

const appReducer = combineReducers({
    clients,
    languages,
    videos,
    galleries,
    projects,
    others,
    users
})

const rootReducer = (state, action) => {
    if (action.type === SUCCESS_LOGOUT) {
        storage.removeItem('persist:root')
        state = undefined
    }
    return appReducer(state, action)
}
export default rootReducer