import React from 'react';

import { Route, Switch } from "react-router-dom";

import { Layout } from 'antd';
import Clients from '../../../views/clients';
import Projects from '../../../views/projects';
import Languages from '../../../views/Languages';
import Galleries from '../../../views/Gallery';
import Videos from '../../../views/Videos';
import UserProfile from '../../../views/profile';
import ChangePwd from '../../../views/changepwd';


const { Content } = Layout;
const PageLayout = () => {
   // const {children} = props;
    return (
        <Content
                className="site-layout-background"
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                }}
            >
                <Switch>
                    <Route path="/clients" component={Clients} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/languages" component={Languages} />
                    <Route path="/gallery" component={Galleries} />
                    <Route path="/videos" component={Videos} />
                    <Route path="/userprofile" component={UserProfile} />
                    <Route path="/chgpwd" component={ChangePwd} />

                </Switch>
            </Content>
    );
};

export default PageLayout;