import { SUCCESS_GET_VIDEOS, SUCCESS_GET_VIDEO, SUCCESS_CREATEVIDEO, SUCCESS_UPDATEVIDEO, SUCCESS_DELETEVIDEO } from "../actions/videos.action"

const initialState = {
    videos: []
}
let video = [];
export const videos = (state = initialState, { type, payload }) => {
    switch (type) {
        case SUCCESS_GET_VIDEOS:
            return {
                ...state, ...payload
            }
        case SUCCESS_GET_VIDEO:
            return {
                ...state, ...payload
            }
        case SUCCESS_CREATEVIDEO:
            return {
                videos: [...state.videos, { ...payload }]
            }
        case SUCCESS_UPDATEVIDEO:
            const index = state.videos.indexOf(state.videos.find(item => payload.id === item.id));
            video = [...state.videos];
            if (index > -1) {
                video[index].name = payload.name;
                video[index].code = payload.code;
                video[index].description = payload.description;
            }
            return {
                ...state,
                videos: video
            };
        case SUCCESS_DELETEVIDEO:
            video = state.videos.filter(item => {
                return payload.id !== item.id;
            });
            return {
                ...state,
                videos: video
            };
        default: return state;
    }
} 
