import React, { Component } from 'react';

import { Modal } from 'antd';
export default class FormModal extends Component {

    render() {
        const { children, title, visible, handleOk,width, handleCancel } = this.props;
        return (
            <div>
                <Modal
                    title={title}
                    visible={visible}
                     onOk={handleOk}
                    onCancel={handleCancel}
                    //  wrapClassName="ant-modal-centered"
                    footer={null}
                    width={width && 600}
                    className="custom-modal-v1"
                    wrapClassName="ant-modal-centered"
                >

                    {children}
                </Modal>

            </div>
        );
    }
}
