import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from "./modules/stores"
import { PersistGate } from 'redux-persist/integration/react'
const {store,persistor} = configureStore();

const Root = (
    <Provider store={store}>
       
        <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate> 
    </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));

