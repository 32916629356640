import {
    createAction
} from 'redux-actions';


export const REQUEST_CREATELANGUAGE = 'REQUEST_CREATELANGUAGE';
export const SUCCESS_CREATELANGUAGE = 'SUCCESS_CREATELANGUAGE';
export const FAILURE_CREATELANGUAGE = 'FAILURE_CREATELANGUAGE';
export const requestCreateLanguage = createAction(REQUEST_CREATELANGUAGE);
export const successCreateLanguage = createAction(SUCCESS_CREATELANGUAGE);
export const failureCreateLanguage = createAction(FAILURE_CREATELANGUAGE);

export const REQUEST_CREATETRANSLATE = 'REQUEST_CREATETRANSLATE';
export const SUCCESS_CREATETRANSLATE = 'SUCCESS_CREATETRANSLATE';
export const FAILURE_CREATETRANSLATE = 'FAILURE_CREATETRANSLATE';
export const requestCreateTranslate = createAction(REQUEST_CREATETRANSLATE);
export const successCreateTranslate = createAction(SUCCESS_CREATETRANSLATE);
export const failureCreateTranslate = createAction(FAILURE_CREATETRANSLATE);


export const REQUEST_GET_LANGUAGE = 'REQUEST_GET_LANGUAGE';
export const SUCCESS_GET_LANGUAGE = 'SUCCESS_GET_LANGUAGE';
export const FAILURE_GET_LANGUAGE = 'FAILURE_GET_LANGUAGE';
export const requestGetLanguage = createAction(REQUEST_GET_LANGUAGE);
export const successGetLanguage = createAction(SUCCESS_GET_LANGUAGE);
export const failureGetLanguage = createAction(FAILURE_GET_LANGUAGE);

export const REQUEST_GET_LANGUAGES = 'REQUEST_GET_LANGUAGES';
export const SUCCESS_GET_LANGUAGES = 'SUCCESS_GET_LANGUAGES';
export const FAILURE_GET_LANGUAGES = 'FAILURE_GET_LANGUAGES';
export const requestGetLanguages = createAction(REQUEST_GET_LANGUAGES);
export const successGetLanguages = createAction(SUCCESS_GET_LANGUAGES);
export const failureGetLanguages = createAction(FAILURE_GET_LANGUAGES);


export const REQUEST_UPDATELANGUAGE = 'REQUEST_UPDATELANGUAGE';
export const SUCCESS_UPDATELANGUAGE = 'SUCCESS_UPDATELANGUAGE';
export const FAILURE_UPDATELANGUAGE = 'FAILURE_UPDATELANGUAGE';
export const requestUpdateLanguage = createAction(REQUEST_UPDATELANGUAGE);
export const successUpdateLanguage = createAction(SUCCESS_UPDATELANGUAGE);
export const failureUpdateLanguage = createAction(FAILURE_UPDATELANGUAGE);


export const REQUEST_DELETELANGUAGE = 'REQUEST_DELETELANGUAGE';
export const SUCCESS_DELETELANGUAGE = 'SUCCESS_DELETELANGUAGE';
export const FAILURE_DELETELANGUAGE = 'FAILURE_DELETELANGUAGE';
export const requestDeleteLanguage = createAction(REQUEST_DELETELANGUAGE);
export const successDeleteLanguage = createAction(SUCCESS_DELETELANGUAGE);
export const failureDeleteLanguage = createAction(FAILURE_DELETELANGUAGE);


