import React from 'react';
import { withRouter } from 'react-router-dom';


import UpdatePassword from './EditForm';
import PageTitle from '../../components/common/PageTitle';

const ChangePwd = () => {

    return (
        <div>
            <PageTitle title={"Change Password"}/>
            <UpdatePassword  />
        </div>
    );
};

export default withRouter(ChangePwd);