import axios from "axios";
import baseURL from "./"
//const baseURL = 'https://api.citybibles.ga/v1'

//Client API
export const AddClient = async (token, name, code, description, location) => {
    try {

        const result = await axios({
            method: 'post',
            url: `${baseURL}/clients`,
            data: {
                name,
                code,
                description,
                location
            },
            withCredentials: true,
            
            headers: {
                //  'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
               // 'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
        })

      /*   const result = await axios.post(
            `${baseURL}/clients`, {
            name,
            code,
            description,
            location
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        ); */
        return result
    } catch (error) {
        return await error.response;
    }
};
export const UpdateClient = async (token, id, name, code, description, location) => {
    try {
        const result = await axios({
            method: 'patch',
            url: `${baseURL}/clients/${id}`,
            data: {
                name,
                code,
                description,
                location
            },
            withCredentials: true,
            
            headers: {
                //  'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
               // 'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
        })
        
      /*   const result = await axios.patch(
            `${baseURL}/clients/${id}`, {
            name, code, description, location
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        ); */
        return result
    } catch (error) {
        return await error.response;
    }
};
export const DeleteClient = async (token, id) => {
    try {
        const result = await axios.delete(
            `${baseURL}/clients/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const GetClients = async (token) => {
    try {
        const result = await axios.get(`${baseURL}/clients`
            , {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        );
        return result;
    } catch (error) {
     //   console.log(error.response)
        return await error.response;
    }
};


export const GetClient = async (token, id) => {
    try {
        const result = await axios.get(`${baseURL}/clients/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return result;
    } catch (error) {
        return await error.response;
    }
};
