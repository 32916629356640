import React from 'react';
import Blockies from 'react-blockies';
import { Layout, Menu, Dropdown } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    SettingFilled
} from '@ant-design/icons';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { requestLogout } from '../../../modules/actions/user.action';
import { withRouter, Link } from 'react-router-dom';
const { Header } = Layout;
const MainHeader = (props) => {
    const handleLogout = () => {
        //   console.log("Testing Logout")
        sessionStorage.removeItem('isAuthenticated')
        props.Logout();
        props.history.replace({
            pathname: '/login',
        })
    }
/*     const handlePwdChange = () => {
        props.history.replace({
            pathname: '/app/chgpwd',
        })
    }
    const handleChangeProfile = () => {
        props.history.replace({
            pathname: '/app/userprofile',
        })
    } */

    const avatarDropdown = (
        <Menu onClick={props.handleMenuClick}>
            <Menu.Item key="1">
                <Link to="/userprofile">
                    <span>
                        <UserOutlined />
                        &nbsp;&nbsp;&nbsp;{"Profile"}
                    </span>
                </Link>


            </Menu.Item>
            <Menu.Item key="2">
                <Link to="/chgpwd">
                    <span>
                        <SettingFilled />
                        &nbsp;&nbsp;&nbsp;{"Change Pwd"}
                    </span>
                </Link>


            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                {/*  <Link to="/login"> */}
                <span onClick={() => handleLogout()}>
                    <LogoutOutlined />
                    &nbsp;&nbsp;&nbsp;{"Logout"}
                </span>
                {/*  </Link> */}

            </Menu.Item>
        </Menu>
    );
    return (
        <React.Fragment>
            <Header className="site-layout-background app-header-inner bg-info" style={{ padding: 0 }} >
                {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: props.toggle,
                })}
                {/*    <Avatar style={{ float: "right", margin: 16 }} size="large" icon={<UserOutlined />} /> */}
                <div style={{ float: "right", margin: 16 }}>

                    <Dropdown
                        overlay={avatarDropdown}
                        onVisibleChange={props.handleVisibleChange}
                        visible={props.visible}
                    >
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Blockies
                                seed={props.user.email}
                            />
                        </a>
                    </Dropdown>

                </div>

            </Header>
        </React.Fragment>
    );
};
MainHeader.propTypes = {
    Logout: PropTypes.func.isRequired,
    // isAuthenticated: PropTypes.bool.isRequired,

}
//Login

const mapStateToProps = (state) => {

    const isAuthenticated = state.users.isAuthenticated
    const loading = state.others.isShow
    const message = state.others.message
   // console.log(state.users.user)
    return {
        user: state.users.user,
        isAuthenticated, loading, message
        //  flashmsg
    }
}

export default connect(mapStateToProps, { Logout: requestLogout })(withRouter(MainHeader));