import {
    call,
    fork,
    take,
    put,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';

import { LoginUser, Logout, UpdatePassword, UpdateUser, DeleteUser } from "../api/users.api"
import {
    REQUEST_LOGIN,
    successLogin,
    failureLogin,
    REQUEST_LOGOUT,
    failureUpdatePassword,
    failureCreateUser,
    failureUpdateUser,
    failureDeleteUser,
    REQUEST_UPDATEUSER,
    REQUEST_DELETEUSER
} from '../actions/user.action';
import { requestSetCurrentUser, requestSetFlashMsg, requestSetLoader, SET_FLASH_MSG } from "../actions/others.action"
import setAuthorizationToken from '../utils/setAuthToken';
import jwtDecode from "jwt-decode"
import { successUpdatePassword, REQUEST_UPDATEPASSWORD, successCreateUser, successUpdateUser, successDeleteUser, REQUEST_CREATEUSER, successLogout } from './../actions/user.action';
import { AddUser } from './../api/users.api';

import { history } from './../../helpers/History';

import AlertMessage from '../../components/shared/Alert/message';
import openNotificationWithIcon from '../../components/shared/Alert/notification';

const AddNewUser = async (data) => {

    const result = await AddUser(data);
    return {
        result
    }
}
function* handleSubmitNewAdd({ payload: { values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)
    const { result } = yield call(AddNewUser, values);

    if (result.status === 201) {
        yield put(successCreateUser(result.data.result.user))

        AlertMessage("d")
        openNotificationWithIcon("success", 'Save Status', "User Saved Successfully")

    } else {

        AlertMessage("d")
        openNotificationWithIcon('error', 'Saving Error', result.data.message)

        yield put(failureCreateUser(result.error))
    }
}


const UpdateExistUser = async (token, data) => {
    const id = data.id;
    const result = await UpdateUser(id, data)
    return result
}

function* handleSubmitUpdateUser({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)

    const result = yield call(UpdateExistUser, token, values);

    if (result.status === 201) {
        yield put(successUpdateUser(result.data.result.user))

        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "User Updated Successfully")

    } else {
        yield put(requestSetLoader({ isShow: false }))
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Error', result.data.message)

        yield put(failureUpdateUser(result.error))
    }
}


/* Delete Existing clients */

const DeleteExistUser = async (data) => {
    const { id } = data;
    const result = await DeleteUser(id);
    return result;
}

function* handleSubmitDeleteUser({ payload: { values } }) {

    const result = yield call(DeleteExistUser, values);
    if (result.status === 201) {
        yield put(successDeleteUser(result.data.user))
        yield put(requestSetLoader({ isShow: false }))
        const msg = {
            type: result.data.status,
            msg: "User Deleted Successfully"
        }
        yield put(requestSetFlashMsg(msg))
    } else {
        yield put(requestSetLoader({ isShow: false }))
        const msg = {
            type: result.data.status,
            msg: result.error
        }
        yield put(requestSetFlashMsg(msg))
        yield put(failureDeleteUser(result.error))
    }
}


const Login = async (data) => {
    const {
        email,
        password
    } = data
    const result = await LoginUser(email, password);
    return {
        result
    }
}

const chgPwd = async (data) => {
    const { id, oldpassword, password } = data;
    const result = await UpdatePassword(id, oldpassword, password)
    return { result }
}

function* handleChangePwd({ payload: { values } }) {

    AlertMessage("loading", "Updating...", "loading", 0)
    const { result } = yield call(chgPwd, values);

    if (result.status === 201) {
        yield put(successUpdatePassword(result.data.user))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "Password Changed Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Error', result.data.message)

        yield put(failureUpdatePassword(result.data.message))
    }
}

function* handleSubmitLogin({ payload: { values } }) {

    AlertMessage("loading", "Authenticating...", "loading", 0)
    const { result } = yield call(Login, values);
    if (result.status === 200) {
        const token = result.data.token

        sessionStorage.setItem('jwtToken', token);
        sessionStorage.setItem('isAuthenticated', 'true');
        setAuthorizationToken(token)
        const decode = jwtDecode(token)
        const user = {
            ...decode
        }
        AlertMessage("d")
        AlertMessage("success", result.data.message, "success", 1)
        yield put(requestSetCurrentUser(jwtDecode(token)))
        yield put(successLogin(user))
        history.push('/')
    } else {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('jwtToken');
        yield put(failureLogin(result.data.message))
        AlertMessage("d")
        AlertMessage("error", result.data.message, "error", 3)
    }
}


const LogoutHandler = async () => {
    const result = await Logout();
    return { result }
}

function* setCurrentUser() {
    yield take(SET_FLASH_MSG)
    yield take(REQUEST_LOGOUT)
}
function* HandleLogout() {
    const { result } = yield call(LogoutHandler);

    if (result.status === 200) {
        yield put(requestSetCurrentUser({}))
        yield put(requestSetFlashMsg({}))
        yield put(successLogout())
        sessionStorage.removeItem('jwtToken')
        setAuthorizationToken(false)

    }
}
function* watchLogs() {
    yield takeLatest(REQUEST_LOGIN, handleSubmitLogin)
    yield takeEvery(REQUEST_UPDATEPASSWORD, handleChangePwd)
    yield takeLatest(REQUEST_LOGOUT, HandleLogout)
    yield takeEvery(REQUEST_CREATEUSER, handleSubmitNewAdd)
    yield takeEvery(REQUEST_UPDATEUSER, handleSubmitUpdateUser)
    yield takeEvery(REQUEST_DELETEUSER, handleSubmitDeleteUser)
}

export default function* rootSaga() {
    yield fork(watchLogs)
    yield fork(setCurrentUser)
}