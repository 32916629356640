import { SUCCESS_GET_CLIENTS, SUCCESS_GET_CLIENT, SUCCESS_CREATECLIENT, SUCCESS_UPDATECLIENT, SUCCESS_DELETECLIENT, SET_CLIENTID } from "../actions/clients.action"

const initialState = {
    clients: [],
    clientID: ""
}

let client = [];
export const clients = (state = initialState, { type, payload }) => {
    switch (type) {
        case SUCCESS_GET_CLIENTS:
            return {
                ...state, ...payload
            }
        case SUCCESS_GET_CLIENT:
            return {
                ...state, ...payload
            }
        case SET_CLIENTID:
            return {
                ...state, ...payload
            }
        case SUCCESS_CREATECLIENT:
            return {
                clients: [...state.clients, { ...payload }]
            }
        case SUCCESS_UPDATECLIENT:
            const index = state.clients.indexOf(state.clients.find(item => payload.id === item.id));
            client = [...state.clients];
            if (index > -1) {
                client[index].name = payload.name;
                client[index].code = payload.code;
                client[index].description = payload.description;
                client[index].location = payload.location;
            }
            return {
                ...state,
                clients: client
            };

        case SUCCESS_DELETECLIENT:
            client = state.clients.filter(item => {
                return payload.id !== item.id;
            });
            return {
                ...state,
                clients: client
            };
        default: return state;
    }
} 
