import { SUCCESS_GET_LANGUAGES, SUCCESS_GET_LANGUAGE, SUCCESS_CREATELANGUAGE, SUCCESS_UPDATELANGUAGE, SUCCESS_DELETELANGUAGE, SUCCESS_CREATETRANSLATE } from "../actions/languages.action"

const initialState = {
    languages: [],
    translates: []
}

let language = [];
export const languages = (state = initialState, { type, payload }) => {
    switch (type) {
        case SUCCESS_GET_LANGUAGES:
            return {
                ...state, ...payload
            }
        case SUCCESS_GET_LANGUAGE:
            return {
                ...state, ...payload
            }
        case SUCCESS_CREATELANGUAGE:
            return {
                ...state,

                languages: [...state.languages, { ...payload }]
            }
        /*  return {
             ...state, ...payload
         } */
        case SUCCESS_CREATETRANSLATE:
        //console.log(payload)
        /*  return {
             ...state,
             
             translates: [...state.translates, { ...payload }]
         } */

        /*    return {
               ...state, ...payload
           } */
        case SUCCESS_UPDATELANGUAGE:
            const index = state.languages.indexOf(state.languages.find(item => payload.id === item.id));
            language = [...state.languages];
            if (index > -1) {
                language[index].name = payload.name;
                language[index].code = payload.code;
                language[index].description = payload.description;
            }
            return {
                ...state,
                languages: language
            };

        case SUCCESS_DELETELANGUAGE:
         
            language = state.languages.filter(item => {
                return payload.id !== item.id;
            });
            return {
                ...state,
                languages: language
            };
        default: return state;
    }
} 
