import {
    createAction
} from 'redux-actions';

export const SET_LOADER = 'SET_LOADER';
export const requestSetLoader = createAction(SET_LOADER);

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const requestSetCurrentUser = createAction(SET_CURRENT_USER);

export const SET_FLASH_MSG = 'SET_FLASH_MSG';
export const requestSetFlashMsg = createAction(SET_FLASH_MSG);