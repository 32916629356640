import {
    createAction
} from 'redux-actions';


export const REQUEST_CREATECLIENT = 'REQUEST_CREATECLIENT';
export const SUCCESS_CREATECLIENT = 'SUCCESS_CREATECLIENT';
export const FAILURE_CREATECLIENT = 'FAILURE_CREATECLIENT';
export const requestCreateClient = createAction(REQUEST_CREATECLIENT);
export const successCreateClient = createAction(SUCCESS_CREATECLIENT);
export const failureCreateClient = createAction(FAILURE_CREATECLIENT);


export const REQUEST_GET_CLIENT = 'REQUEST_GET_CLIENT';
export const SUCCESS_GET_CLIENT = 'SUCCESS_GET_CLIENT';
export const FAILURE_GET_CLIENT = 'FAILURE_GET_CLIENT';
export const requestGetClient = createAction(REQUEST_GET_CLIENT);
export const successGetClient = createAction(SUCCESS_GET_CLIENT);
export const failureGetClient = createAction(FAILURE_GET_CLIENT);

export const REQUEST_GET_CLIENTS = 'REQUEST_GET_CLIENTS';
export const SUCCESS_GET_CLIENTS = 'SUCCESS_GET_CLIENTS';
export const FAILURE_GET_CLIENTS = 'FAILURE_GET_CLIENTS';
export const requestGetClients = createAction(REQUEST_GET_CLIENTS);
export const successGetClients = createAction(SUCCESS_GET_CLIENTS);
export const failureGetClients = createAction(FAILURE_GET_CLIENTS);


export const SET_CLIENTID = 'SET_CLIENTID'
export const requestSetClientID = createAction(SET_CLIENTID);


export const REQUEST_UPDATECLIENT = 'REQUEST_UPDATECLIENT';
export const SUCCESS_UPDATECLIENT = 'SUCCESS_UPDATECLIENT';
export const FAILURE_UPDATECLIENT = 'FAILURE_UPDATECLIENT';
export const requestUpdateClient = createAction(REQUEST_UPDATECLIENT);
export const successUpdateClient = createAction(SUCCESS_UPDATECLIENT);
export const failureUpdateClient = createAction(FAILURE_UPDATECLIENT);


export const REQUEST_DELETECLIENT = 'REQUEST_DELETECLIENT';
export const SUCCESS_DELETECLIENT = 'SUCCESS_DELETECLIENT';
export const FAILURE_DELETECLIENT = 'FAILURE_DELETECLIENT';
export const requestDeleteClient = createAction(REQUEST_DELETECLIENT);
export const successDeleteClient = createAction(SUCCESS_DELETECLIENT);
export const failureDeleteClient = createAction(FAILURE_DELETECLIENT);


