import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChipTable from './../../components/DataTable/index';
import NewProject from './NewForm';
import FormModal from '../../components/modals/FormModal';
import PageTitle from '../../components/common/PageTitle';
import { Popconfirm, Button, Input, message, Dropdown, Menu, Badge } from 'antd';
import { requestGetProjects, requestDeleteProject, requestSetProjectID } from "../../modules/actions/projects.action"
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EyeFilled, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import EditProject from './EditForm';
let searchInput;

const Projects = (props) => {

    const [visible, setVisible] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [selData, SetSelData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);

    const [state, setState] = React.useState({
        searchText: '',
        searchedColumn: '',
    });
    //this.props.location.state.detail
    React.useEffect(() => {
        setLoading(true);
        //  console.log(props.clientID)
        props.GetProjects({ token: sessionStorage.getItem("jwtToken"), client: props.clientID })

        delayTime(3000)
    }, [false])

    const delay = time => {
        return new Promise((resolve, reject) => {
            if (isNaN(time)) {
                reject(new Error("Delay Requires a Valid Number"));
            } else {
                setTimeout(resolve, time);
            }
        });
    };

    const delayTime = async time => {
        await delay(time)
            .then(() => {
                setLoading(false);
                setHasData(true);
            })
    };

    const handleOk = (e) => {
        console.log(e);
        setVisible(false);
        setShow(false);
    }

    const handleCancel = (e) => {
        console.log(e);
        setVisible(false);
        setShow(false);
    }

    function confirm(e, record) {
        //  console.log(e, record);
        props.DeleteProject({ token: sessionStorage.getItem("jwtToken"), values: record })
     //   message.success('Project Deleted');
    }

    function cancel(e) {
        console.log(e);
        message.error('Click on No');
    }



    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleNextPage = (record) => {
        /*    return <Link
                to={{pathname: "/app/gallery",state: { detail: record, clientID: props.clientID }
                    
                   // search: "?sort=name",
                   // hash: "#the-hash",
                   
                }}
            /> */
        props.selectProject({ projectID: record.id })
        props.history.push({
            pathname: '/gallery',
            state: { detail: record, clientID: props.clientID }
        })
    }

    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '', searchedColumn: '' });
    };

    const EditDataHandler = (record) => {
        SetSelData(record)
        setShow(true);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps('description'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <span style={{ marginRight: 16 }}>
                        <Dropdown

                            overlay={
                                (<Menu>
                                    <Menu.Item key="1">
                                        <Button type="link" onClick={() => EditDataHandler(record)} icon={<EditOutlined />} size="large">
                                            Edit Project
                                </Button>


                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <Badge className="site-badge-count-109" style={{ backgroundColor: '#52c41a' }} >
                                            <Button type="dashed" size="large" onClick={() => handleNextPage(record)}> <EyeFilled /> View Items</Button>
                                            {/*  <Link to={{ pathname: "/app/gallery", search: `${record.id}?${props.clientID}`, state: { detail: record, clientID: props.clientID } }} > <EyeFilled /> View Items </Link> */}
                                        </Badge>
                                    </Menu.Item>
                                </Menu>)

                            } placement="bottomRight" trigger={['click']}>
                            <Button type="primary"
                                shape="circle"
                                icon={<MoreOutlined style={{ fontWeight: 700 }} />}
                                size="large" />
                        </Dropdown>
                    </span>

                    <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => confirm(e, record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" shape="circle" danger icon={<DeleteOutlined />} size="large" />
                    </Popconfirm>
                </>
            ),
        },
    ];



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    return (
        <div>
            <PageTitle title={"Projects"}
                button="Add Project"
                click={() => setVisible(true)}
            />

            <FormModal
                children={<NewProject clientID={props.clientID} />}
                title={"Add New Project"}
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            <FormModal
                children={<EditProject data={selData} clientID={props.clientID} />}
                title={"Edit  Project"}
                visible={show}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            <ChipTable
                id={Math.random()}
                key={Math.random()}
                data={props.projects}
                columns={columns}
                rowSelection={rowSelection}
                loading={loading}
                hasData={hasData}
            />
        </div>
    );
};
Projects.prototype = {
    GetProjects: PropTypes.func.isRequired,
    DeleteProject: PropTypes.func.isRequired,
    clientID: PropTypes.string.isRequired
}
const mapStateToProps = (state) => {
 
    const projects = state.projects.projects && state.projects.projects.map(item => {
        return {
            id: item.id,
            key: item.id,
            code: item.code,
            client: item.client,
            name: item.name,
            description: item.description
        }
    })
   
    const user = state.users.user
    const clientID = state.clients.clientID 
    return {
        projects,
        clientID,user
    }
}

export default connect(mapStateToProps, { GetProjects: requestGetProjects, DeleteProject: requestDeleteProject, selectProject: requestSetProjectID })(withRouter(Projects));