import {
    createAction
} from 'redux-actions';

/* USER Actions Start Here */

export const REQUEST_CREATEUSER = 'REQUEST_CREATEUSER';
export const SUCCESS_CREATEUSER = 'SUCCESS_CREATEUSER';
export const FAILURE_CREATEUSER = 'FAILURE_CREATEUSER';
export const requestCreateUser = createAction(REQUEST_CREATEUSER);
export const successCreateUser = createAction(SUCCESS_CREATEUSER);
export const failureCreateUser = createAction(FAILURE_CREATEUSER);


export const REQUEST_UPDATEUSER = 'REQUEST_UPDATEUSER';
export const SUCCESS_UPDATEUSER = 'SUCCESS_UPDATEUSER';
export const FAILURE_UPDATEUSER = 'FAILURE_UPDATEUSER';
export const requestUpdateUser = createAction(REQUEST_UPDATEUSER);
export const successUpdateUser = createAction(SUCCESS_UPDATEUSER);
export const failureUpdateUser = createAction(FAILURE_UPDATEUSER);

export const REQUEST_UPDATEPASSWORD = 'REQUEST_UPDATEPASSWORD';
export const SUCCESS_UPDATEPASSWORD = 'SUCCESS_UPDATEPASSWORD';
export const FAILURE_UPDATEPASSWORD = 'FAILURE_UPDATEPASSWORD';
export const requestUpdatePassword = createAction(REQUEST_UPDATEPASSWORD);
export const successUpdatePassword = createAction(SUCCESS_UPDATEPASSWORD);
export const failureUpdatePassword = createAction(FAILURE_UPDATEPASSWORD);

export const REQUEST_GET_USER = 'REQUEST_GET_USER';
export const SUCCESS_GET_USER = 'SUCCESS_GET_USER';
export const FAILURE_GET_USER = 'FAILURE_GET_USER';
export const requestGetUser = createAction(REQUEST_GET_USER);
export const successGetUser = createAction(SUCCESS_GET_USER);
export const failureGetUser = createAction(FAILURE_GET_USER);

export const REQUEST_DELETEUSER = 'REQUEST_DELETEUSER';
export const SUCCESS_DELETEUSER = 'SUCCESS_DELETEUSER';
export const FAILURE_DELETEUSER = 'FAILURE_DELETEUSER';
export const requestDeleteUser = createAction(REQUEST_DELETEUSER);
export const successDeleteUser = createAction(SUCCESS_DELETEUSER);
export const failureDeleteUser = createAction(FAILURE_DELETEUSER);


export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const FAILURE_LOGIN = 'FAILURE_LOGIN';
export const requestLogin = createAction(REQUEST_LOGIN);
export const successLogin = createAction(SUCCESS_LOGIN);
export const failureLogin = createAction(FAILURE_LOGIN);


export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';
export const FAILURE_LOGOUT = 'FAILURE_LOGOUT';
export const requestLogout = createAction(REQUEST_LOGOUT);
export const successLogout = createAction(SUCCESS_LOGOUT);
export const failureLogout = createAction(FAILURE_LOGOUT);
