import React from "react";
import {
  Form,
  Button,
  Upload,
  Select,
  Spin,
  notification,
  message,
} from "antd";

import { connect } from "react-redux";

import PropTypes from "prop-types";

//import axios from 'axios';

import { PlusOutlined } from "@ant-design/icons";
import { requestCreateVideo } from "../../modules/actions/videos.action";
//const { Text } = Typography;
const { Option } = Select;
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const NewVideo = (props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState([]);
  //  const [previewVisible, setPreviewVisible] = React.useState(false);
  // const [previewImage, setPreviewImage] = React.useState("");
  const checkLanguage = (language) => (language.id ? language.id : language);
  const FileAlreadyExist = (project, language, reference) =>
    props.videos &&
    props.videos.find(
      (item) =>
        checkLanguage(item.language) === language &&
        item.item === reference &&
        item.project === project
    );
  const onFinish = (values) => {
    const chkExist = FileAlreadyExist(
      props.projectID,
      values.language,
      props.itemID
    );
    //   console.log(chkExist)
    if (chkExist) return message.error("Language already Exist");
    //   message.success("ready to upload")
    const data = {
      project: props.projectID,
      item: props.itemID,
      vuforia: props.vuforia,
      avatar: fileList[0].originFileObj,
      ...values,
    };
    console.log(data);
    props.AddNewVideo({
      token: sessionStorage.getItem("jwtToken"),
      values: data,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const NormalFile = (e) => {
    console.log(e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  //  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    //   console.log(file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file);
    }

    const selected = file.url || file.preview;
    return selected;
  };

  const uploadButton = (
    <Button>
      <PlusOutlined />
    </Button>
  );
  return (
    <>
      <Form
        /*  {...layout} */
        name="basic"
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        size="large"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
      >
        <Form.Item
          name="language"
          label="Select Language"
          hasFeedback
          rules={[{ required: true, message: "Please Select your country!" }]}
        >
          <Select showSearch placeholder="Select a Country">
            {props.languages &&
              props.languages.map(({ name, id }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          // name="file"
          valuePropName="filelist"
          getValueFromEvent={NormalFile}
          label="Select Video"
        >
          <Upload
            name="avatar"
            accept="video/*"
            listType="picture"
            customRequest={dummyRequest}
            fileList={fileList}
            onChange={handleChange}
            previewFile={handlePreview}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
NewVideo.prototype = {
  AddNewVideo: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
  vuforia: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
  console.log(props);
  return {
    // clientID: props.clientID,
    vuforia: props.vuforia,
    projectID: props.projectID,
    languages: props.languages,

    videos: props.videos,
  };
};

export default connect(mapStateToProps, { AddNewVideo: requestCreateVideo })(
  NewVideo
);
