
import {
    call,
    fork,
    put,
    takeEvery
} from 'redux-saga/effects';
import { AddClient, UpdateClient, DeleteClient, GetClient, GetClients } from './../api/clients.api';
import { REQUEST_CREATECLIENT, successCreateClient, failureCreateClient, REQUEST_UPDATECLIENT, successUpdateClient, failureUpdateClient, REQUEST_DELETECLIENT, successDeleteClient, failureDeleteClient, successGetClient, failureGetClient, REQUEST_GET_CLIENT, REQUEST_GET_CLIENTS, failureGetClients, successGetClients } from './../actions/clients.action';
import { requestSetLoader, requestSetFlashMsg } from '../actions/others.action';
import AlertMessage from '../../components/shared/Alert/message';
import openNotificationWithIcon from '../../components/shared/Alert/notification';

const AddNewClient = async (token, data) => {
    const {
        name, code, description, location
    } = data
    const result = await AddClient(token, name, code, description, location);
    return {
        result
    }
}
function* handleSubmitNewClient({ payload: { token, values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)
    const { result } = yield call(AddNewClient, token, values);

    if (result.status === 201) {
        yield put(successCreateClient(result.data.result.client))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Save', "Client Saved Successfully")

    } else {

        yield put(failureCreateClient(result.error))
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', result.error)
    }
}


const UpdateExistClient = async (token, data) => {
    const { id, name, code, description, location } = data;
    const result = await UpdateClient(token, id, name, code, description, location)
    return result
}

function* handleSubmitUpdateclients({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)
    const result = yield call(UpdateExistClient, token, values);

    if (result.status === 201) {
        yield put(successUpdateClient(result.data.result.client))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "Client Updated Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Data Error', result.data.message)

        yield put(failureUpdateClient(result.error))
    }
}


/* Delete Existing clients */

const DeleteExistClient = async (token, data) => {
    const { id } = data;
    const result = await DeleteClient(token, id);
    return result;
}

function* handleSubmitDeleteclients({ payload: { token, values } }) {
    AlertMessage("loading", "Deleting...", "loading", 0)
    const result = yield call(DeleteExistClient, token, values);
    if (result.status === 201) {
        yield put(successDeleteClient(result.data.client))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Delete Status', "Client Deleted Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Delete Data Error', result.error)

        yield put(failureDeleteClient(result.error))
    }
}

/* Get clients */

const getOneClients = async (token, id) => {
    const result = await GetClient(token, id);
    return result
}

/* Get clients Handler */
function* runGetClient({ payload: { token, id } }) {
    const result = yield call(getOneClients, token, id)
    if (result.status === 200) {
        yield put(successGetClient({

            clients: result.data.results.clients
        }));
    } else {
        yield put(failureGetClient(result.error));
    }
}

const getAllClients = async (token) => {
    const result = await GetClients(token);
    return result
}

function* runGetClients({ payload: { token } }) {
    const result = yield call(getAllClients, token)
    if (result.status === 200) {
        yield put(successGetClients({

            clients: result.data.result.clients
        }));
    } else {
        yield put(failureGetClients(result.error));
    }
}

/* Get clients Watcher */
function* watchGetClients() {
    yield takeEvery(REQUEST_GET_CLIENTS, runGetClients)
    yield takeEvery(REQUEST_GET_CLIENT, runGetClient)
    yield takeEvery(REQUEST_CREATECLIENT, handleSubmitNewClient)
    yield takeEvery(REQUEST_UPDATECLIENT, handleSubmitUpdateclients)
    yield takeEvery(REQUEST_DELETECLIENT, handleSubmitDeleteclients)
}

export default function* rootSaga() {
    yield fork(watchGetClients)
}
