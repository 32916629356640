
import {
    call,
    fork,
    // take,
    put,
    takeEvery
} from 'redux-saga/effects';
import { AddGallery, UpdateGallery, DeleteGallery, GetGallery, GetGalleries } from '../api/gallery.api';
import { REQUEST_CREATEGALLERY, successCreateGallery, failureCreateGallery, REQUEST_UPDATEGALLERY, successUpdateGallery, failureUpdateGallery, REQUEST_DELETEGALLERY, successDeleteGallery, failureDeleteGallery, successGetGallery, failureGetGallery, REQUEST_GET_GALLERY, REQUEST_GET_GALLERIES, failureGetGalleries, successGetGalleries } from '../actions/items.action';
import { requestSetFlashMsg, requestSetLoader } from '../actions/others.action';
import AlertMessage from '../../components/shared/Alert/message';
import openNotificationWithIcon from '../../components/shared/Alert/notification';

const AddNewGallery = async (token, data) => {
    const {
        name, avatar, project, client
    } = data
    const result = await AddGallery(token, name, avatar, project, client);
    return {
        result
    }
}

function* handleSubmitNewGallery({ payload: { token, values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)

    const {
        result
    } = yield call(AddNewGallery, token, values);
    // console.log(result)
    if (result.status === 201) {
        yield put(successCreateGallery(result.data.result.item))

        AlertMessage("d")
        openNotificationWithIcon("success", 'Save Status', "Image Saved Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', result.error)

        yield put(failureCreateGallery(result.error))

    }
}


const UpdateExistGallery = async (token, data) => {
    const { id, name, avatar } = data;
    const result = await UpdateGallery(token, id, name, avatar)
    return result
}

function* handleSubmitUpdateGallery({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)


    const result = yield call(UpdateExistGallery, token, values);

    if (result.status === 201) {
        yield put(successUpdateGallery(result.data.result.item))
       
        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "Image Updated Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Data Error', result.error)

        yield put(failureUpdateGallery(result.error))
    }
}


/* Delete Existing Items */

const DeleteExistGallery = async (token, data) => {
    const { id } = data;
    const result = await DeleteGallery(token, id);
    return result;
}

function* handleSubmitDeleteGallery({ payload: { token, values } }) {
    AlertMessage("loading", "Deleting...", "loading", 0)

    const result = yield call(DeleteExistGallery, token, values);
    if (result.status === 201) {
        yield put(successDeleteGallery(result.data.item))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Delete Status', "Image Deleted Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Delete Data Error', result.error)
        yield put(failureDeleteGallery(result.error))
    }
}

/* Get Items */

const getOneGallery = async (token, client, id) => {
    const result = await GetGallery(token, client, id);
    return result
}

/* Get Items Handler */
function* runGetGallery({ payload: { token, client, id } }) {
    const result = yield call(getOneGallery, token, client, id)
    if (result.status === 200) {
        yield put(successGetGallery({

            items: result.data.results.items
        }));
    } else {
        yield put(failureGetGallery(result.error));
    }
}

const getAllGalleries = async (token, project) => {
    const result = await GetGalleries(token, project);
    return result
}

function* runGetGalleries({ payload: { token, project } }) {
    const result = yield call(getAllGalleries, token, project)
    // console.log(result)
    if (result.status === 200) {
        yield put(successGetGalleries({

            galleries: result.data.result.items
        }));
    } else {
        yield put(failureGetGalleries(result.error));
    }
}

/* Get Items Watcher */
function* watchGetGalleries() {
    yield takeEvery(REQUEST_GET_GALLERIES, runGetGalleries)
    yield takeEvery(REQUEST_GET_GALLERY, runGetGallery)
    yield takeEvery(REQUEST_CREATEGALLERY, handleSubmitNewGallery)
    yield takeEvery(REQUEST_UPDATEGALLERY, handleSubmitUpdateGallery)
    yield takeEvery(REQUEST_DELETEGALLERY, handleSubmitDeleteGallery)
}

export default function* rootSaga() {
    yield fork(watchGetGalleries)
}
