
import jwtDecode from 'jwt-decode';
const CheckTokenExpired = (token) => {
    if (token) {
        var decodedToken = jwtDecode(token)
        var dateNow = parseInt(new Date().getTime().toString())/ 1000;
        if (decodedToken.exp < dateNow) {
            return true
        } else {
            return false
        }
    }

}


export default CheckTokenExpired