import {
    createAction
} from 'redux-actions';


export const REQUEST_CREATEVIDEO = 'REQUEST_CREATEVIDEO';
export const SUCCESS_CREATEVIDEO = 'SUCCESS_CREATEVIDEO';
export const FAILURE_CREATEVIDEO = 'FAILURE_CREATEVIDEO';
export const requestCreateVideo = createAction(REQUEST_CREATEVIDEO);
export const successCreateVideo = createAction(SUCCESS_CREATEVIDEO);
export const failureCreateVideo = createAction(FAILURE_CREATEVIDEO);


export const REQUEST_GET_VIDEO = 'REQUEST_GET_VIDEO';
export const SUCCESS_GET_VIDEO = 'SUCCESS_GET_VIDEO';
export const FAILURE_GET_VIDEO = 'FAILURE_GET_VIDEO';
export const requestGetVideo = createAction(REQUEST_GET_VIDEO);
export const successGetVideo = createAction(SUCCESS_GET_VIDEO);
export const failureGetVideo = createAction(FAILURE_GET_VIDEO);

export const REQUEST_GET_VIDEOS = 'REQUEST_GET_VIDEOS';
export const SUCCESS_GET_VIDEOS = 'SUCCESS_GET_VIDEOS';
export const FAILURE_GET_VIDEOS = 'FAILURE_GET_VIDEOS';
export const requestGetVideos = createAction(REQUEST_GET_VIDEOS);
export const successGetVideos = createAction(SUCCESS_GET_VIDEOS);
export const failureGetVideos = createAction(FAILURE_GET_VIDEOS);


export const REQUEST_UPDATEVIDEO = 'REQUEST_UPDATEVIDEO';
export const SUCCESS_UPDATEVIDEO = 'SUCCESS_UPDATEVIDEO';
export const FAILURE_UPDATEVIDEO = 'FAILURE_UPDATEVIDEO';
export const requestUpdateVideo = createAction(REQUEST_UPDATEVIDEO);
export const successUpdateVideo = createAction(SUCCESS_UPDATEVIDEO);
export const failureUpdateVideo = createAction(FAILURE_UPDATEVIDEO);


export const REQUEST_DELETEVIDEO = 'REQUEST_DELETEVIDEO';
export const SUCCESS_DELETEVIDEO = 'SUCCESS_DELETEVIDEO';
export const FAILURE_DELETEVIDEO = 'FAILURE_DELETEVIDEO';
export const requestDeleteVideo = createAction(REQUEST_DELETEVIDEO);
export const successDeleteVideo = createAction(SUCCESS_DELETEVIDEO);
export const failureDeleteVideo = createAction(FAILURE_DELETEVIDEO);


