import React from 'react';
import { Form, Input, Button, Spin, notification, } from 'antd';
import { requestUpdateClient } from '../../modules/actions/clients.action';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditClient = (props) => {
    const { data } = props;
    const [form] = Form.useForm();
    React.useEffect(() => {
        form.setFieldsValue({
            name: data.name,
            code: data.code,
            description: data.description,
            location: data.location,
        });

    }, [data])
    const onFinish = values => {
        const id = data.id;
        const datax = {
            id,
            ...values
        }

        props.UpdateClient({ token: sessionStorage.getItem("jwtToken"), values: { ...datax } });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };




    return (


        <Form
            /*  {...layout} */
            name="basic"
            initialValues={{
                remember: true,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
        >
            <Form.Item
                //  size="large"
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Client Name',
                    },
                ]}
            >
                <Input placeholder="Client Name" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Code"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Code',
                    },
                ]}
            >
                <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Description',
                    },
                ]}
            >
                <Input placeholder="Description" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Location"
                name="location"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Location',
                    },
                ]}
            >
                <Input placeholder="Location" />
            </Form.Item>


            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
        </Button>
            </Form.Item>
        </Form>

    );
};
EditClient.prototype = {
    UpdateClient: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    Loader: PropTypes.func.isRequired,
    Messenger: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    message: PropTypes.object.isRequired,
}
const mapStateToProps = (state, props) => {
    const data = props.data;
    // const loading = state.others.isShow
    //  const message = state.others.message
    return {
        data,// loading, message
    }
}
export default connect(mapStateToProps, { UpdateClient: requestUpdateClient })(EditClient);
