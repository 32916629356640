import {
    createAction
} from 'redux-actions';


export const REQUEST_CREATEGALLERY = 'REQUEST_CREATEGALLERY';
export const SUCCESS_CREATEGALLERY = 'SUCCESS_CREATEGALLERY';
export const FAILURE_CREATEGALLERY = 'FAILURE_CREATEGALLERY';
export const requestCreateGallery = createAction(REQUEST_CREATEGALLERY);
export const successCreateGallery = createAction(SUCCESS_CREATEGALLERY);
export const failureCreateGallery = createAction(FAILURE_CREATEGALLERY);


export const SET_GALLERYIDS = 'SET_GALLERYIDS'
export const requestSetGalleryIDS = createAction(SET_GALLERYIDS);

export const REQUEST_GET_GALLERY = 'REQUEST_GET_GALLERY';
export const SUCCESS_GET_GALLERY = 'SUCCESS_GET_GALLERY';
export const FAILURE_GET_GALLERY = 'FAILURE_GET_GALLERY';
export const requestGetGallery = createAction(REQUEST_GET_GALLERY);
export const successGetGallery = createAction(SUCCESS_GET_GALLERY);
export const failureGetGallery = createAction(FAILURE_GET_GALLERY);

export const REQUEST_GET_GALLERIES = 'REQUEST_GET_GALLERIES';
export const SUCCESS_GET_GALLERIES = 'SUCCESS_GET_GALLERIES';
export const FAILURE_GET_GALLERIES = 'FAILURE_GET_GALLERIES';
export const requestGetGalleries = createAction(REQUEST_GET_GALLERIES);
export const successGetGalleries = createAction(SUCCESS_GET_GALLERIES);
export const failureGetGalleries = createAction(FAILURE_GET_GALLERIES);


export const REQUEST_UPDATEGALLERY = 'REQUEST_UPDATEGALLERY';
export const SUCCESS_UPDATEGALLERY = 'SUCCESS_UPDATEGALLERY';
export const FAILURE_UPDATEGALLERY = 'FAILURE_UPDATEGALLERY';
export const requestUpdateGallery = createAction(REQUEST_UPDATEGALLERY);
export const successUpdateGallery = createAction(SUCCESS_UPDATEGALLERY);
export const failureUpdateGallery = createAction(FAILURE_UPDATEGALLERY);


export const REQUEST_DELETEGALLERY = 'REQUEST_DELETEGALLERY';
export const SUCCESS_DELETEGALLERY = 'SUCCESS_DELETEGALLERY';
export const FAILURE_DELETEGALLERY = 'FAILURE_DELETEGALLERY';
export const requestDeleteGallery = createAction(REQUEST_DELETEGALLERY);
export const successDeleteGallery = createAction(SUCCESS_DELETEGALLERY);
export const failureDeleteGallery = createAction(FAILURE_DELETEGALLERY);


