import {
    createAction
} from 'redux-actions';


export const REQUEST_CREATEPROJECT = 'REQUEST_CREATEPROJECT';
export const SUCCESS_CREATEPROJECT = 'SUCCESS_CREATEPROJECT';
export const FAILURE_CREATEPROJECT = 'FAILURE_CREATEPROJECT';
export const requestCreateProject = createAction(REQUEST_CREATEPROJECT);
export const successCreateProject = createAction(SUCCESS_CREATEPROJECT);
export const failureCreateProject = createAction(FAILURE_CREATEPROJECT);

export const SET_PROJECTID = 'SET_PROJECTID'
export const requestSetProjectID = createAction(SET_PROJECTID);


export const REQUEST_GET_PROJECT = 'REQUEST_GET_PROJECT';
export const SUCCESS_GET_PROJECT = 'SUCCESS_GET_PROJECT';
export const FAILURE_GET_PROJECT = 'FAILURE_GET_PROJECT';
export const requestGetProject = createAction(REQUEST_GET_PROJECT);
export const successGetProject = createAction(SUCCESS_GET_PROJECT);
export const failureGetProject = createAction(FAILURE_GET_PROJECT);

export const REQUEST_GET_PROJECTS = 'REQUEST_GET_PROJECTS';
export const SUCCESS_GET_PROJECTS = 'SUCCESS_GET_PROJECTS';
export const FAILURE_GET_PROJECTS = 'FAILURE_GET_PROJECTS';
export const requestGetProjects = createAction(REQUEST_GET_PROJECTS);
export const successGetProjects = createAction(SUCCESS_GET_PROJECTS);
export const failureGetProjects = createAction(FAILURE_GET_PROJECTS);


export const REQUEST_UPDATEPROJECT = 'REQUEST_UPDATEPROJECT';
export const SUCCESS_UPDATEPROJECT = 'SUCCESS_UPDATEPROJECT';
export const FAILURE_UPDATEPROJECT = 'FAILURE_UPDATEPROJECT';
export const requestUpdateProject = createAction(REQUEST_UPDATEPROJECT);
export const successUpdateProject = createAction(SUCCESS_UPDATEPROJECT);
export const failureUpdateProject = createAction(FAILURE_UPDATEPROJECT);


export const REQUEST_DELETEPROJECT = 'REQUEST_DELETEPROJECT';
export const SUCCESS_DELETEPROJECT = 'SUCCESS_DELETEPROJECT';
export const FAILURE_DELETEPROJECT = 'FAILURE_DELETEPROJECT';
export const requestDeleteProject = createAction(REQUEST_DELETEPROJECT);
export const successDeleteProject = createAction(SUCCESS_DELETEPROJECT);
export const failureDeleteProject = createAction(FAILURE_DELETEPROJECT);


