import React from 'react';
import { Form, Input, Button, notification, Spin } from 'antd';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { requestCreateProject } from '../../modules/actions/projects.action';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};


const NewProject = (props) => {
    const [form] = Form.useForm();
    const onFinish = values => {
        const data = {
            client: props.clientID,
            ...values
        }

        props.AddNewProject({ token: sessionStorage.getItem("jwtToken"), values: data });
        //  form.resetFields();
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    return (

        <Form
            /*  {...layout} */
            name="basic"
            initialValues={{
                remember: true,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
        >
            <Form.Item
                //  size="large"
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Project Name',
                    },
                ]}
            >
                <Input placeholder="Project Name" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Code"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Code',
                    },
                ]}
            >
                <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Description',
                    },
                ]}
            >
                <Input placeholder="Description" />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>

    );
};
NewProject.prototype = {
    AddNewProject: PropTypes.func.isRequired,
    clientID: PropTypes.string.isRequired,

}

const mapStateToProps = (state, props) => {

    return {
        clientID: props.clientID,

    }
}

export default connect(mapStateToProps, { AddNewProject: requestCreateProject })(NewProject);