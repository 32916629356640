import { SUCCESS_GET_GALLERIES, SUCCESS_GET_GALLERY, SUCCESS_CREATEGALLERY, SUCCESS_UPDATEGALLERY, SUCCESS_DELETEGALLERY, SET_GALLERYIDS } from "../actions/items.action"

const initialState = {
    galleries: [],
    imageID: {
        itemID: "",
        vuforia: ""
    }

}
let gallery = [];
export const galleries = (state = initialState, { type, payload }) => {
    switch (type) {
        case SUCCESS_GET_GALLERIES:
            return {
                ...state, ...payload
            }
        case SUCCESS_GET_GALLERY:
            return {
                ...state, ...payload
            }
        case SET_GALLERYIDS:
            return {
                ...state, ...payload
            }
        case SUCCESS_CREATEGALLERY:
            return {
                galleries: [...state.galleries, { ...payload }]
            }
        case SUCCESS_UPDATEGALLERY:
            const index = state.galleries.indexOf(state.galleries.find(item => payload.id === item.id));
            gallery = [...state.galleries];
            if (index > -1) {
                gallery[index].name = payload.name;
                gallery[index].code = payload.code;
                gallery[index].description = payload.description;
            }
            return {
                ...state,
                galleries: gallery
            };
        case SUCCESS_DELETEGALLERY:
            gallery = state.galleries.filter(item => {
                return payload.id !== item.id;
            });
            return {
                ...state,
                galleries: gallery
            };
        default: return state;
    }
} 
