import React from 'react';
/**/ import "./assets/css/layouts__BasicLayout.css"
import "./assets/css/p__DashboardLite__DashboardLite.css"
import "./assets/css/style.css"
import "./assets/css/css.css"
import "./assets/css/newstyle.css"
import './App.css'
import {
 // BrowserRouter as Router,
 Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layouts from "./components/Layout/AppLayout"
import LoginPage from "./views/login";

import { PrivateRoute } from './helpers/PrivateRoute';

import { history } from './helpers/History';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route exact path="/" render={() => <Redirect to="/clients" />} />
        <PrivateRoute path="/" component={Layouts} />
     {/*    <Route path="*" component={Error} /> */}
        {/* <Redirect from="*" to="/" /> */}
      </Switch>
    </Router>

  );
}

export default App;
