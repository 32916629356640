import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChipTable from './../../components/DataTable/index';
import NewLanguage from './NewForm';
import FormModal from '../../components/modals/FormModal';
import PageTitle from '../../components/common/PageTitle';
import { Popconfirm, Button, Input, message, Dropdown, Menu } from 'antd';
import { requestGetLanguages, requestDeleteLanguage, requestGetLanguage } from "../../modules/actions/languages.action"
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import EditLanguage from './EditForm';
import Translate from './TranslateForm';

let searchInput;

//

const Languages = (props) => {

    const [visible, setVisible] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [showTranslate, setShowTranslate] = React.useState(false);
    const [selData, SetSelData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);
    const [translateData, setTranslateData] = React.useState({})

    const [state, setState] = React.useState({
        searchText: '',
        searchedColumn: '',
    });

    React.useEffect(() => {
        setLoading(true);
        props.GetLanguages({ token: sessionStorage.getItem("jwtToken"), client: props.clientID })
        props.GetTranslates({ token: sessionStorage.getItem("jwtToken") })

        delayTime(3000)
    }, [false])

    const delay = time => {
        return new Promise((resolve, reject) => {
            if (isNaN(time)) {
                reject(new Error("Delay Requires a Valid Number"));
            } else {
                setTimeout(resolve, time);
            }
        });
    };

    const delayTime = async time => {
        await delay(time)
            .then(() => {
                setLoading(false);
                setHasData(true);
            })
    };

    const handleOk = (e) => {
        // console.log(e);
        setVisible(false);
        setShow(false);
    }

    const handleCancel = (e) => {

        setVisible(false);
        setShow(false);
        setShowTranslate(false);
    }

    function confirm(e, record) {
        //    console.log(e, record);
        props.DeleteLanguage({ token: sessionStorage.getItem("jwtToken"), values: record })
        
    }

    function cancel(e) {
        //  console.log(e);
        message.error('Click on No');
    }



    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    /*     const handleNextPage = (record) => {
            props.history.push({
                pathname: '/app/projects',
                state: { detail: record }
            })
        }
     */
    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '', searchedColumn: '' });
    };

    const EditDataHandler = (record) => {
        SetSelData(record)
        setShow(true);
    }
    const TranslateDataHandler = (record) => {
        SetSelData(record)
        //  console.log(record.id)
        const translates = props.translates && props.translates.filter(item => item.lang === record.id)
        setTranslateData(translates)
        setShowTranslate(true);
    }


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps('description'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <span style={{ marginRight: 16 }}>
                        <Dropdown

                            overlay={
                                (<Menu>
                                    <Menu.Item key="1">
                                        <Button type="link" onClick={() => EditDataHandler(record)} icon={<EditOutlined />} size="large">
                                            Edit Language
                                    </Button>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <Button type="link" onClick={() => TranslateDataHandler(record)} icon={<PlusOutlined />} size="large">
                                            Translate
                                    </Button>
                                    </Menu.Item>

                                </Menu>)

                            } placement="bottomRight" trigger={['click']}>
                            <Button type="primary"
                                shape="circle"
                                icon={<MoreOutlined style={{ fontWeight: 700 }} />}
                                size="large" />
                        </Dropdown>
                    </span>

                    <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => confirm(e, record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" shape="circle" danger icon={<DeleteOutlined />} size="large" />
                    </Popconfirm>
                </>
            ),
        },
    ];



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    return (
        <div>
            <PageTitle title={"Languages"}
                button="Add Language"
                click={() => setVisible(true)}
            />

            <FormModal
                children={<NewLanguage clientID={props.clientID} />}
                title={"Add New Language"}
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            <FormModal
                children={<EditLanguage data={selData} />}
                title={"Edit  Language"}
                visible={show}
                handleOk={handleOk}
                handleCancel={handleCancel}
                
            />
            <FormModal
                children={<Translate data={translateData} lang={selData.id} clientID={props.clientID} />}
                title={`Translate ${selData.name}  Language`}
                width={true}
                visible={showTranslate}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            <ChipTable
                id={[Math.random()]}
                key={Math.random()}
                data={props.languages}
                columns={columns}
                rowSelection={rowSelection}
                loading={loading}
                hasData={hasData}
            />
        </div>
    );
};
Languages.prototype = {
    GetLanguages: PropTypes.func.isRequired,
    DeleteLanguage: PropTypes.func.isRequired,
    clientID: PropTypes.string.isRequired
}
const mapStateToProps = (state, props) => {
  
    const languages = state.languages.languages && state.languages.languages.map(item => {
        return {
            id: item.id,
            key: item.id,
            code: item.code,
            client: item.client,
            name: item.name,
            description: item.description
        }
    })
    const translates = state.languages.translates
    const clientID = state.clients.clientID
    return {
        languages,
        clientID,
        translates
    }
}

export default connect(mapStateToProps, { GetLanguages: requestGetLanguages, GetTranslates: requestGetLanguage, DeleteLanguage: requestDeleteLanguage })(withRouter(Languages));