import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChipTable from '../../components/DataTable/index';
import NewVideo from './NewForm';
import FormModal from '../../components/modals/FormModal';
import PageTitle from '../../components/common/PageTitle';
import { Popconfirm, Button, Input, message } from 'antd';
import { requestGetVideos, requestDeleteVideo } from "../../modules/actions/videos.action"
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
//import EditVideo from './EditForm';
import ReactPlayer from 'react-player'
import { requestGetLanguages } from '../../modules/actions/languages.action';
let searchInput;

const Videos = (props) => {
    //  console.log(props)
    const [visible, setVisible] = React.useState(false);
    //const [show, setShow] = React.useState(false);
    //  const [selData, SetSelData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);

    const [state, setState] = React.useState({
        searchText: '',
        searchedColumn: '',
    });
    //this.props.location.state.detail
    React.useEffect(() => {

        setLoading(true);
        props.GetVideos({ token: sessionStorage.getItem("jwtToken"), item: props.vuforia })
        props.GetLanguages({ token: sessionStorage.getItem("jwtToken"), client: props.clientID })

        delayTime(3000)
    }, [false])

    const delay = time => {
        return new Promise((resolve, reject) => {
            if (isNaN(time)) {
                reject(new Error("Delay Requires a Valid Number"));
            } else {
                setTimeout(resolve, time);
            }
        });
    };

    const delayTime = async time => {
        await delay(time)
            .then(() => {
                setLoading(false);
                setHasData(true);
            })
    };

    const handleOk = (e) => {
        console.log(e);
        setVisible(false);
        // setShow(false);
    }

    const handleCancel = (e) => {
        console.log(e);
        setVisible(false);
        //   setShow(false);
    }

    function confirm(e, record) {
        //  console.log(e, record);
        props.DeleteVideo({ token: sessionStorage.getItem("jwtToken"), values: record })
        message.success('Video Deleted');
    }

    function cancel(e) {
        console.log(e);
        message.error('Click on No');
    }



    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    /*    const handleNextPage = (record) => {
           props.history.push({
               pathname: '/app/videos',
               state: { detail: record, projectID: props.projectID }
           })
       }
    */

    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '', searchedColumn: '' });
    };

    /*   const EditDataHandler = (record) => {
          SetSelData(record)
          setShow(true);
      } */

    const columns = [
        {
            title: 'Video',
            dataIndex: 'url',
            key: 'url',
            ...getColumnSearchProps('url'),
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            ...getColumnSearchProps('language'),
        }, {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            ...getColumnSearchProps('createdDate'),
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Are you sure delete?"
                        onConfirm={(e) => confirm(e, record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" shape="circle" danger icon={<DeleteOutlined />} size="large" />
                    </Popconfirm>
                </>
            ),
        },
    ];



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    return (
        <div>
            <PageTitle title={"Videos"}
                button="Add Video"
                click={() => setVisible(true)}
            />

            <FormModal
                children={<NewVideo itemID={props.itemID} languages={props.languages} vuforia={props.vuforia} projectID={props.projectID} videos={props.videoChecker} />}
                title={"Add New Video"}
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            {/*   <FormModal
                children={<EditVideo data={selData} />}
                title={"Edit  Video"}
                visible={show}
                handleOk={handleOk}
                handleCancel={handleCancel}
            /> */}

            <ChipTable
                id={[Math.random()]}
                key={Math.random()}
                data={props.videos}
                columns={columns}
                rowSelection={rowSelection}
                loading={loading}
                hasData={hasData}
            />
        </div>
    );
};
Videos.prototype = {
    GetVideos: PropTypes.func.isRequired,
    DeleteVideo: PropTypes.func.isRequired,
    itemID: PropTypes.string.isRequired,
    clientID: PropTypes.string.isRequired,
    projectID: PropTypes.string.isRequired,
    vuforia: PropTypes.string.isRequired,
    videos: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
}
const mapStateToProps = (state, props) => {
    const GetLanguage = (lang) => state.languages.languages && state.languages.languages.find(item => item.id === lang);
     console.log(state.videos.videos)
    const baseUrl = "https://cityapp-production.s3.us-east-2.amazonaws.com/production/uploads/city-bibles-scanning-app/video/url/"
    const videos = state.videos.videos && state.videos.videos.map(({ url, createdAt, id, language, vuforia, project }) => {
        const created_date = new Date(createdAt);
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ];
        var year = created_date.getFullYear();
        var month = months[created_date.getMonth()];
        var date = created_date.getDate();
        //  console.log(language)
        //  const lang =  language !== null|| language.name ? language.name : language !== null  ? GetLanguage(language).name : null
        const lang = language === null ? {} : language.name ? language.name : GetLanguage(language).name
        //  console.log(lang)

        var cdate =
            date +
            ", " +
            month +
            " " +
            year;
        return {
            id,
            key: id,
            vuforia,
            project,
            createdDate: cdate,
            language: lang,
            url: <ReactPlayer url={`${baseUrl}${url}`} playing={false} controls={true} width={200} height={150} />
        }
    })
    //  console.log(state)
    const itemID = state.galleries.imageID.itemID
    const projectID = state.projects.projectID  //props.location.state.d[etail.id
    const clientID = state.clients.clientID  //props.location.state.clientID
    const user = state.users.user
    const vuforia = state.galleries.imageID.vuforia

    return {
        videos, user,
        itemID,
        clientID,
        vuforia,
        projectID,
        videoChecker: state.videos.videos,
        languages: state.languages.languages
    }
}

export default connect(mapStateToProps, { GetLanguages: requestGetLanguages, GetVideos: requestGetVideos, DeleteVideo: requestDeleteVideo })(withRouter(Videos));