import axios from "axios";

//const baseURL = 'https://api.citybibles.ga/v1/items'
//const baseURL = 'http://localhost:4000/v1/items'
//const baseURL = 'https://citybible.herokuapp.com/v1/items'

import baseURL from "./"
//Client API
/* const options = {
    onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor(loaded * 100 / total)
        //   console.log(`${loaded}kb of ${total}kb | ${percent}`)
    }
} */
export const AddVideo = async (token, avatar, language, project, item, vuforia) => {

    var form_data = new FormData();
    form_data.append('avatar', avatar);
    form_data.set("language", language)
    form_data.set("project", project)
    form_data.set("item", item)
    form_data.set("vuforia", vuforia)
    //  console.log(form_data)
    try {
        const result = await axios.post(`${baseURL}/items/videos`, form_data, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': "Bearer " + token
           //    'Acces-Control-Allow-Origin': 'https://city-bible.surge.sh'
            }
        })
        /*   const result = await axios({
              method: 'post',
              url: `${baseURL}/videos`,
              data: form_data,
              withCredentials: true,
              headers: {
                  //  'Content-Type': 'multipart/form-data',
                  'Authorization': "Bearer " + token
              },
              onUploadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent;
                  let percent = Math.floor(loaded * 100 / total)
              //    console.log(`${loaded}kb of ${total}kb | ${percent}`)
              }
          }) */
        //  console.log(result)
        return result
    }
    catch (error) {
        //  console.log(error.response);
        return await error.response;
    }


};

export const UpdateVideo = async (token, id, avatar) => {
    try {
        const result = await axios.patch(
            `${baseURL}/items/videos/${id}`, {
            avatar
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};
export const DeleteVideo = async (token, id) => {
    try {
        const result = await axios.delete(
            `${baseURL}/items/videos/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }
        );
        return result
    } catch (error) {
        return await error.response;
    }
};

export const GetVideos = async (token, vuforia) => {
    try {
        const result = await axios.get(`${baseURL}/items/${vuforia}/videos`
            , {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        );
        return result;
    } catch (error) {
        //    console.log(error.response)
        return await error.response;
    }
};


export const GetVideo = async (token, item, id) => {
    try {
        const result = await axios.get(`${baseURL}/items/${item}/videos/${id}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return result;
    } catch (error) {
        return await error.response;
    }
};

