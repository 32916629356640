
import {
    call,
    fork,
  //  take,
    put,
    takeEvery
} from 'redux-saga/effects';
import { AddVideo, UpdateVideo, DeleteVideo, GetVideo, GetVideos } from '../api/videos.api';
import { REQUEST_CREATEVIDEO, successCreateVideo, failureCreateVideo, REQUEST_UPDATEVIDEO, successUpdateVideo, failureUpdateVideo, REQUEST_DELETEVIDEO, successDeleteVideo, failureDeleteVideo, successGetVideo, failureGetVideo,  REQUEST_GET_VIDEOS, failureGetVideos, successGetVideos, REQUEST_GET_VIDEO } from '../actions/videos.action';
import { requestSetLoader, requestSetFlashMsg } from '../actions/others.action';
import AlertMessage from '../../components/shared/Alert/message';
import openNotificationWithIcon from '../../components/shared/Alert/notification';

const AddNewVideo = async (token, data) => {
    const {
        avatar, language, project, item, vuforia
    } = data
    const result = await AddVideo(token, avatar, language, project, item, vuforia);
    return {
        result
    }
}

function* handleSubmitNewVideo({ payload: { token, values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)

    const {
        result
    } = yield call(AddNewVideo, token, values);
   // console.log(result)
    if (result.status === 201) {
        yield put(successCreateVideo(result.data.result.video))
        AlertMessage("d")
        openNotificationWithIcon("success", 'Save Status', "Video Saved Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', result.error)

        yield put(failureCreateVideo(result.data.error))
    }
}


const UpdateExistVideo = async (token, data) => {
    const { id, avatar } = data;
    const result = await UpdateVideo(token, id, avatar)
    return result
}

function* handleSubmitUpdateVideo({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)


    const result = yield call(UpdateExistVideo, token, values);

    if (result.status === 201) {
        yield put(successUpdateVideo(result.data.result.video))

        AlertMessage("d")
        openNotificationWithIcon("success", 'Update Status', "Video Updated Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Update Data Error', result.error)

        yield put(failureUpdateVideo(result.error))
    }
}


/* Delete Existing Items */

const DeleteExistVideo = async (token, data) => {
    const { id } = data;
    const result = await DeleteVideo(token, id);
    return result;
}

function* handleSubmitDeleteVideo({ payload: { token, values } }) {
    AlertMessage("loading", "Deleting...", "loading", 0)

    const result = yield call(DeleteExistVideo, token, values);
  //  console.log(result)
    if (result.status === 201) {
        yield put(successDeleteVideo(result.data.video))
      
        AlertMessage("d")
        openNotificationWithIcon("success", 'Delete Status', "Video Deleted Successfully")

    } else {
        AlertMessage("d")
        openNotificationWithIcon('error', 'Delete Data Error', result.error)

        yield put(failureDeleteVideo(result.data.error))
    }
}

/* Get Items */

const getOneVideo = async (token, item, id) => {
    const result = await GetVideo(token, item, id);
    return result
}

/* Get Items Handler */
function* runGetVideo({ payload: { token, item, id } }) {
    const result = yield call(getOneVideo, token, item, id)
    if (result.status === 200) {
        yield put(successGetVideo({

            videos: result.data.results.videos
        }));
    } else {
        yield put(failureGetVideo(result.error));
    }
}

const getAllVideos = async (token, item) => {
    const result = await GetVideos(token, item);
    return result
}

function* runGetVideos({ payload: { token, item } }) {
    const result = yield call(getAllVideos, token, item)
   // console.log(result)
    if (result.status === 200) {
        yield put(successGetVideos({

            videos: result.data.result.videos
        }));
    } else {
        yield put(failureGetVideos(result.error));
    }
}

/* Get Items Watcher */
function* watchGetVideos() {
    yield takeEvery(REQUEST_GET_VIDEOS, runGetVideos)
    yield takeEvery(REQUEST_GET_VIDEO, runGetVideo)
    yield takeEvery(REQUEST_CREATEVIDEO, handleSubmitNewVideo)
    yield takeEvery(REQUEST_UPDATEVIDEO, handleSubmitUpdateVideo)
    yield takeEvery(REQUEST_DELETEVIDEO, handleSubmitDeleteVideo)
}

export default function* rootSaga() {

    yield fork(watchGetVideos)
}
