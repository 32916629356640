import React from 'react';
import LoginForm from './loginForm'
import cardImage from "../../assets/covers/logo-white.png"



const LoginPage = () => {

  const FormCard = () => (
    <section className="form-card-page form-card row no-gutters">
      <div className="form-card__img form-card__img--left col-lg-6" style={{ backgroundColor: '#E1E01A', backgroundImage: `url(${cardImage})` }}></div>
      <div className="form-card__body col-lg-6 p-5 px-lg-8 d-flex align-items-center">
        <section className="form-v1-container">
          <h2>Login to Continue</h2>
          <p className="lead">.......... Welcome back, sign in with your City Bible account .............</p>

          <LoginForm />
        </section>
      </div>
    </section>
  )

  return (
    <div >
      <FormCard />
    </div>
  );
};

export default LoginPage;