import React from 'react';
import { Layout } from 'antd';

import Logo from '../Logo';
import SideNav from './SideNav';

const { Sider } = Layout;


const Sidebar = (props) => {

    return (
        <React.Fragment>
            <Sider trigger={null} collapsible collapsed={props.collapsed}>
                <Logo />
                <SideNav />
            </Sider>
        </React.Fragment>
    );
};

export default Sidebar;