import React from 'react';

const Logo = () => (
  <svg className="logo-img logo-react" viewBox="0 0 3925 3525" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle className="react-dot" stroke="none" cx="1960" cy="1760" r="355" />
    <g className="react-curve" strokeWidth="170" fill="none">
      <ellipse cx="2575" cy="545" rx="715" ry="1875" transform="rotate(30)" />
      <ellipse cx="1760" cy="-1960" rx="715" ry="1875" transform="rotate(90)" />
      <ellipse cx="-815" cy="-2505" rx="715" ry="1875" transform="rotate(-210)" />
    </g>
  </svg>
)

export default Logo;