import React from "react";
import { PageHeader, Button } from "antd";

export default function PageTitle(props) {


    return (
        <React.Fragment key={props}>
            <PageHeader
                className="site-page-header"
                ghost={false}
                onBack={() => window.history.back()}
                title={props.title}
                extra={[
                    props.button && <Button type="primary" key={1} onClick={props.click}>  {props.button}</Button>
                    
                ]} 
            />
        </React.Fragment>

    );
}

