import React from 'react';
import { Form, Input, Button, Spin, notification, } from 'antd';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestUpdatePassword } from '../../modules/actions/user.action';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const UpdatePassword = (props) => {
 const { user } = props;
    const [form] = Form.useForm();
    const onFinish = values => {
        const datax = {
            id :user.id,
            ...values
        }
      
         props.UpdatePwd({ token: sessionStorage.getItem("jwtToken"), values: { ...datax } });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };



    return (
       

            <Form
                /*  {...layout} */
                name="basic"
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size="large"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <Form.Item
                    name="oldpassword"
                    label="Old Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirmpassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>


                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Update Password
        </Button>
                </Form.Item>
            </Form>
       
    );
};
UpdatePassword.prototype = {
    UpdatePwd: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  
}
const mapStateToProps = (state) => {
    const user = state.users.user
   
    return {
       
          user
    }
}
export default connect(mapStateToProps, { UpdatePwd: requestUpdatePassword })(UpdatePassword);
