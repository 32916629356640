import React from 'react';
import { Form, Input, Button, Spin, Select, notification, } from 'antd';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  requestUpdateUser } from '../../modules/actions/user.action';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const { Option } = Select;

const UpdatePassword = (props) => {
   

      const { user } = props;
    const [form] = Form.useForm();
    React.useEffect(() => {
   
        form.setFieldsValue({
            fullname: user.fullname,
            email: user.email,
            account_type: user.account_type,

        });

    }, [user])
    const onFinish = values => {
     
        const datax = {
            id:user.id,
            ...values
        }
  
        props.UpdatePwd({ token:sessionStorage.getItem("jwtToken"), values: { ...datax } });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };



    return (
       

            <Form
                /*  {...layout} */
                name="basic"
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size="large"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >

                <Form.Item
                    //  size="large"
                    label="Fullname"
                    name="fullname"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Fullname',
                        },
                    ]}
                >
                    <Input placeholder="Fullname" />
                </Form.Item>
                <Form.Item
                    //  size="large"
                    label="Email"
                    name="email"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Email',
                            type: 'email'
                        },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="account_type"
                    label="Select Type"
                    hasFeedback
                    rules={[{ required: true, message: 'Please Select Type' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select Account Type"
                    >

                        <Option key={0} value={"user"}>user</Option>
                        <Option key={1} value={"admin"}>admin</Option>

                    </Select>
                </Form.Item>



                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Update Profile
        </Button>
                </Form.Item>
            </Form>
        
    );
};
UpdatePassword.prototype = {
    UpdatePwd: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
 
}
const mapStateToProps = (state, props) => {
    const user = state.users.user
  //  const data = props.data;
    
    return {
       
         user
    }
}
export default connect(mapStateToProps, { UpdatePwd: requestUpdateUser })(UpdatePassword);
