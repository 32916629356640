import React from 'react';
import { Table } from 'antd';

const ChipTable = (props) => {
 // console.log(props,props.id)
  return (
    <>
      <Table
        key={props.id}
        //  Key={x}
        size="small"
        columns={props.columns}
        dataSource={props.hasData ? props.data : null}
        loading={props.loading}
        rowSelection={{
          type: "checkbox",
          ...props.rowSelection,
        }}
      />
    </>
  );
};

export default ChipTable;