import { SUCCESS_LOGIN, FAILURE_LOGIN, SUCCESS_LOGOUT } from "../actions/user.action";
import { SET_CURRENT_USER } from "../actions/others.action";
import { isEmpty } from "lodash"

//import storage from 'redux-persist/lib/storage';
const initialState = {
    isAuthenticated: false,
    user: {}
}

export const users = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        case SUCCESS_LOGIN:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        case FAILURE_LOGIN:
            return {
                ...state, ...action.payload
            }
        case SUCCESS_LOGOUT:
          
            return {
                logout: false
            }

        default: return state;
    }
} 