import React from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { requestCreateLanguage } from '../../modules/actions/languages.action';

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};




const NewLanguage = (props) => {
    const [form] = Form.useForm();
    const onFinish = values => {
      //  console.log('Success:', values);
        const data = {
            client: props.clientID,
            ...values
        }
        props.AddNewLanguage({ token: sessionStorage.getItem("jwtToken"), values: data });
        
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

   
    return (
      
        <Form
            /*  {...layout} */
            name="basic"
            initialValues={{
                remember: true,
            }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
        >
            <Form.Item
                //  size="large"
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Language Name',
                    },
                ]}
            >
                <Input placeholder="Language Name" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Code"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Code',
                    },
                ]}
            >
                <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
                //  size="large"
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: 'Please Enter Description',
                    },
                ]}
            >
                <Input placeholder="Description" />
            </Form.Item>


            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
      
    );
};
NewLanguage.prototype = {
    AddNewLanguage: PropTypes.func.isRequired,
    clientID: PropTypes.string.isRequired,
  
}

const mapStateToProps = (state, props) => {
    const loading = state.others.isShow
    const message = state.others.message
    return {
        clientID: props.clientID,
       // loading, message
    }
}


export default connect(mapStateToProps, { AddNewLanguage: requestCreateLanguage })(NewLanguage);