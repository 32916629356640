
import {
    call,
    fork,
    // take,
    put,
    takeEvery
} from 'redux-saga/effects';
import { AddLanguage, UpdateLanguage, DeleteLanguage, GetLanguages, GetTranslates, AddTranslate } from './../api/languages.api';
import { REQUEST_CREATELANGUAGE, successCreateLanguage, failureCreateLanguage, REQUEST_UPDATELANGUAGE, successUpdateLanguage, failureUpdateLanguage, REQUEST_DELETELANGUAGE, successDeleteLanguage, failureDeleteLanguage, successGetLanguage, failureGetLanguage, REQUEST_GET_LANGUAGE, REQUEST_GET_LANGUAGES, failureGetLanguages, successGetLanguages, REQUEST_CREATETRANSLATE, successCreateTranslate, failureCreateTranslate } from './../actions/languages.action';
import { requestSetLoader, requestSetFlashMsg } from '../actions/others.action';

import openNotificationWithIcon from "../../components/shared/Alert/notification"
import AlertMessage from '../../components/shared/Alert/message';

const AddNewLanguage = async (token, data) => {
    const {
        name, code, description, client
    } = data
    const result = await AddLanguage(token, name, code, description, client);
    return {
        result
    }
}

function* handleSubmitNewLanguage({ payload: { token, values } }) {
    AlertMessage("loading", "Saving...", "loading", 0)

    const {
        result
    } = yield call(AddNewLanguage, token, values);

    if (result.status === 201) {
        yield put(successCreateLanguage(result.data.result.language))

        const msg = {
            type: result.data.status,
            msg: "Language Saved Successfully",
            msghead: "Save Language"
        }
        AlertMessage("d")
        openNotificationWithIcon("success", msg.msghead, msg.msg)


    } else {

        const msg = {
            type: result.data.status,
            msg: result.error
        }
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', msg.msg)
        yield put(failureCreateLanguage(result.error))
    }
}

const AddNewTranslate = async (token, data) => {
    const result = await AddTranslate(token, data);
    return {
        result
    }
}

function* handleSubmitNewTranslate({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)
    const {
        result
    } = yield call(AddNewTranslate, token, values);

    if (result.status === 201) {
        yield put(successCreateTranslate(result.data.result.translates))

        const msg = {
            type: result.data.status,
            msg: "Translation Saved Successfully",
            msghead: "Save Translation"
        }
        AlertMessage("d")
        openNotificationWithIcon("success", msg.msghead, msg.msg)

    } else {

        const msg = {
            type: result.data.status,
            msg: result.error
        }
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', msg.msg)
        yield put(failureCreateTranslate(result.error))
    }
}


const UpdateExistLanguage = async (token, data) => {
    const { id, name, code, description } = data;
    const result = await UpdateLanguage(token, id, name, code, description)
    return result
}

function* handleSubmitUpdateLanguages({ payload: { token, values } }) {
    AlertMessage("loading", "Updating...", "loading", 0)


    const result = yield call(UpdateExistLanguage, token, values);

    if (result.status === 201) {
        yield put(successUpdateLanguage(result.data.result.language))

        const msg = {
            type: result.data.status,
            msg: "Language Updated Successfully",
            msghead: "Updated Language"
        }
        AlertMessage("d")
        openNotificationWithIcon("success", msg.msghead, msg.msg)

    } else {

        const msg = {
            type: result.data.status,
            msg: result.error
        }
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', msg.msg)
        yield put(failureUpdateLanguage(result.error))
    }
}


/* Delete Existing Languages */

const DeleteExistLanguage = async (token, data) => {
    const { id } = data;
    const result = await DeleteLanguage(token, id);
    return result;
}

function* handleSubmitDeleteLanguages({ payload: { token, values } }) {
    AlertMessage("loading", "Deleting...", "loading", 0)

    const result = yield call(DeleteExistLanguage, token, values);

    if (result.status === 201) {
        yield put(successDeleteLanguage(result.data.language))

        const msg = {
            type: result.data.status,
            msg: "Language Deleted Successfully",
            msghead: "Deleted Language"
        }
        AlertMessage("d")
        openNotificationWithIcon("success", msg.msghead, msg.msg)
    } else {
        const msg = {
            type: result.data.status,
            msg: result.error
        }
        AlertMessage("d")
        openNotificationWithIcon('error', 'Save Data Error', msg.msg)
        yield put(failureDeleteLanguage(result.error))
    }
}

/* Get Languages */

const getTranslates = async (token) => {
    const result = await GetTranslates(token);
    return result
}

/* Get Languages Handler */
function* runGetTranslate({ payload: { token } }) {
    const result = yield call(getTranslates, token)

    if (result.status === 200) {
        yield put(successGetLanguage({
            translates: result.data.result.translates
        }));
    } else {
        yield put(failureGetLanguage(result.error));
    }
}

const getAllLanguages = async (token) => {
    const result = await GetLanguages(token);
    return result
}

function* runGetLanguages({ payload: { token } }) {
    const result = yield call(getAllLanguages, token)
    if (result.status === 200) {
        yield put(successGetLanguages({

            languages: result.data.result.languages
        }));
    } else {
        yield put(failureGetLanguages(result.error));
    }
}

/* Get Languages Watcher */
function* watchGetLanguages() {
    yield takeEvery(REQUEST_GET_LANGUAGES, runGetLanguages)
    yield takeEvery(REQUEST_GET_LANGUAGE, runGetTranslate)
    yield takeEvery(REQUEST_CREATELANGUAGE, handleSubmitNewLanguage)
    yield takeEvery(REQUEST_CREATETRANSLATE, handleSubmitNewTranslate)
    yield takeEvery(REQUEST_UPDATELANGUAGE, handleSubmitUpdateLanguages)
    yield takeEvery(REQUEST_DELETELANGUAGE, handleSubmitDeleteLanguages)
}

export default function* rootSaga() {

    yield fork(watchGetLanguages)
}
