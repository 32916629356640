import React from 'react';
import { Form, Input, Button, Upload, Modal, Typography, notification, Spin, message } from 'antd';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
//import axios from 'axios';
import { requestCreateGallery } from '../../modules/actions/items.action';

import { PlusOutlined } from '@ant-design/icons';
const { Text } = Typography;
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};



let ccc = false;
const NewGallery = (props) => {
    const [form] = Form.useForm();
    // const [fileSize, SetFileSize] = React.useState(false)
    const [fileList, setFileList] = React.useState([]);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const onFinish = values => {

        if (ccc) {
            return message.error('Image You Selected is Bigger or Equal 2MB!');
        } else {
            const data = {
                project: props.projectID,
                client: props.clientID,
                avatar: fileList[0].originFileObj,
                ...values
            }
            props.AddNewGallery({ token: sessionStorage.getItem("jwtToken"), values: data });

        }
    }
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    }
    const NormalFile = (e) => {
        console.log(e);
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length > 1) {
            e.fileList.shift();
        }
        return e && e.fileList;

    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            //  return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            ccc = true;
            message.error('Image must smaller than 2MB!');
            // return;
        }
        //  SetFileSize(false);
        return isJpgOrPng && isLt2M;
    }

    const handleChange = ({ fileList }) => setFileList(fileList);


    const handleCancel = () => setPreviewVisible(false);
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);

    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );




    return (
        <>

            <Form
                /*  {...layout} */
                name="basic"
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size="large"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <Form.Item
                    //  size="large"
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Name',
                        },
                    ]}
                >
                    <Input placeholder="Name" />
                </Form.Item>

                <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                    {fileList.length > 0 ? <Text type="warning" className="text-center">Hover on Image to View Bigger</Text> : ""}
                </div>


                <Form.Item
                    // name="file"
                    valuePropName="filelist"
                    getValueFromEvent={NormalFile}
                    label="Select File"

                >

                    <Upload name="avatar"
                        style={{ width: 128 }}
                        listType="picture-card"
                        accept="image/*"
                        //  className="avatar-uploader"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}

                        customRequest={dummyRequest}>

                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>

                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" disabled={fileList.length === 0}>
                        Submit
                </Button>
                </Form.Item>

            </Form>


        </>
    );
};
NewGallery.prototype = {
    AddNewGallery: PropTypes.func.isRequired,
    projectID: PropTypes.string.isRequired,
    clientID: PropTypes.string.isRequired,
  
}

const mapStateToProps = (state, props) => {
  
    return {
        clientID: props.clientID,
        projectID: props.projectID,
      
    }
}

export default connect(mapStateToProps, { AddNewGallery: requestCreateGallery, })(NewGallery);