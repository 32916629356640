import React, { Component } from 'react';

import { Form, Input, Button, notification } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestLogin } from '../../modules/actions/user.action';
import { requestSetLoader, requestSetFlashMsg } from '../../modules/actions/others.action';
//import { useHistory } from "react-router-dom";
import { UserOutlined, LockFilled } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

let moved = true;

const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
        message: title,
        description: msg,
    });
};

class LoginForm extends Component {

    formRef = React.createRef();

    onFinish = values => {
        this.props.Login({ values });
        // this.formRef.current.resetFields()
    };

    ShowAlert = () => {
        if (this.props.message.isShow) {
            switch (this.props.message.type) {
                case "success":
                    openNotificationWithIcon(this.props.message.type, 'Save Data', this.props.message.msg)
                    break;

                default:
                    openNotificationWithIcon('error', 'Save Data Error', this.props.message.msg)
                    break;
            }
            this.formRef.current.resetFields()
            this.props.Messenger({})
        }
    }
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    render() {
        const { onFinish, onFinishFailed } = this;
        const { loading } = this.props


        return (
            <div>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    ref={this.formRef}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    size="large"
                >

                    <Form.Item
                        name="email"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter  Email',
                                type: 'email'
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder=" Email" />
                    </Form.Item>
                    <Form.Item
                        hasFeedback
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password',
                            },
                        ]}
                    >
                        <Input.Password prefix={<LockFilled />} type="password" placeholder="Password" />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" htmlType="submit"
                            loading={loading}
                            className="btn-cta btn-block">
                            {loading ? "Authenticating..." : " Log in"}
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        );
    }
}

LoginForm.propTypes = {
    Login: PropTypes.func.isRequired,
    //isAuthenticated: PropTypes.bool.isRequired,
  
}
//Login

const mapStateToProps = (state) => {
    //  console.log(state)
    const isAuthenticated = state.users.isAuthenticated
    //  console.log(isAuthenticated)
    //   const loading = state.others.isShow
    // console.log("testing12365412532....")
    //   const message = state.others.message
    return {
        //     user,
        isAuthenticated,// loading, message
        //  flashmsg
    }
}
export default connect(mapStateToProps, { Login: requestLogin })(withRouter(LoginForm));